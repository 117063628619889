import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import ArsenHeroSVG from "../../component/SVGs/ArsenHeroSVG";
import CheckmarkIcon from "../../component/SVGs/CheckmarkIcon";
import AsoLogoSVG from "../../component/SVGs/AsoLogoSVG";
import AsecLogoSVG from "../../component/SVGs/AsecLogoSVG";
import AsicLogoSVG from "../../component/SVGs/AsicLogoSVG";
import { Arsen2024LogoOnlySVG } from "../../component/SVGs/ArsenLogo2024SVG";
import AstroMascotSVG from "../../component/SVGs/AstroMascotSVG";

function Home() {
  useEffect(() => {
    AOS.init();
  }, []);

  const [openModal, setOpenModal] = useState(false);

  const toggleModal = () => {
    openModal ? setOpenModal(false) : setOpenModal(true);
  };

  const handleLinkClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="flex flex-col gap-2.5 pb-8 mx-4 mt-16">
      {openModal && (
        <div
          data-aos="fade"
          id="defaultModal"
          tabIndex="-1"
          aria-hidden="true"
          className={`fixed top-0 left-0 right-0 z-50 w-full bg-arsen-primary-900/75 p-4 md:inset-0 h-full flex items-center justify-center`}
        >
          <div
            data-aos="fade-up"
            data-aos-delay="100"
            className="w-full max-w-4xl max-h-full"
          >
            {/* Modal content */}
            <div className="relative bg-white rounded-lg shadow max-h-full overflow-y-auto">
              {/* Modal header */}
              <div className="flex items-start justify-between p-4 border-b rounded-t">
                <h3 className="text-xl font-semibold text-gray-900">
                  Panduan Kegiatan ARSEN 2024
                </h3>
                <button
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center"
                  onClick={toggleModal}
                >
                  <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              {/* Modal body */}
              <div className="p-6 space-y-6 overflow-y-auto max-h-[calc(100vh-6rem)] -webkit-overflow-scrolling: touch;">
                <div className="grid grid-cols-2 md:grid-cols-4 py-8 gap-4">
                  <Link
                    to="/opening"
                    onClick={handleLinkClick}
                    className=" bg-white border rounded-lg shadow-md p-4 hover:shadow-none transition duration-500 ease-out"
                  >
                    <div className="flex p-4 justify-center">
                      <Arsen2024LogoOnlySVG sideLength="100" />
                    </div>
                    <h2 className="text-xl font-semibold mb-2 uppercase text-center">
                      Opening
                    </h2>
                    <p className="text-gray-500 mb-4 text-center">
                      Ceremony and Talkshow
                    </p>
                  </Link>
                  <Link
                    to="/asec/panduan/"
                    onClick={handleLinkClick}
                    className=" bg-white border rounded-lg shadow-md p-4 hover:shadow-none transition duration-500 ease-out"
                  >
                    <div className="flex p-4 justify-center">
                      <AsecLogoSVG height="100" />
                    </div>
                    <h2 className="text-xl font-semibold mb-2 uppercase text-center">
                      ASEC
                    </h2>
                    <p className="text-gray-500 mb-4 text-center">
                      Airlangga Statistics Essay Competition
                    </p>
                  </Link>
                  <Link
                    to="/asic/panduan/"
                    onClick={handleLinkClick}
                    className="bg-white border rounded-lg shadow-md p-4 hover:shadow-none transition duration-500 ease-out"
                  >
                    <div className="flex p-4 justify-center">
                      <AsicLogoSVG height="100" />
                    </div>
                    <h2 className="text-xl font-semibold mb-2 uppercase text-center">
                      ASIC
                    </h2>
                    <p className="text-gray-500 mb-4 text-center">
                      Airlangga Statistics Infographic Competition
                    </p>
                  </Link>
                  <Link
                    to="/aso/panduan/"
                    onClick={handleLinkClick}
                    className="bg-white border rounded-lg shadow-md p-4 hover:shadow-none transition duration-500 ease-out"
                  >
                    <div className="flex p-4 justify-center">
                      <AsoLogoSVG height="100" />
                    </div>
                    <h2 className="text-xl font-semibold mb-2 uppercase text-center">
                      ASO
                    </h2>
                    <p className="text-gray-500 mb-4 text-center">
                      Airlangga Statistics Olympiad
                    </p>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="flex flex-col md:flex-row mx-8 px-0 md:px-8">
        <div className="grid content-center">
          <div
            data-aos="fade-up"
            className="text-5xl md:text-5xl font-bold text-center md:text-left mb-4"
          >
            Airlangga Statistics Events
          </div>
          <div
            data-aos="fade-up"
            data-aos-delay="100"
            className="text-lg text-center md:text-left text-gray-700 mb-4"
          >
            Data-Driven Social Equity: Uncovering Inequalities and Advocating
            for Justice
          </div>
          <div
            data-aos="fade-up"
            data-aos-delay="150"
            className="flex gap-2.5 text-md md:text-lg text-center md:text-left text-white mb-8"
          >
            <Link
              to="/events"
              className="bg-arsen-primary hover:bg-black hover:shadow-xl text-white justify-center flex items-center w-full md:w-fit px-8 py-3 rounded-lg transition duration-500"
              onClick={handleLinkClick}
            >
              Daftar Sekarang!
            </Link>
            <button
              onClick={toggleModal}
              className="text-arsen-primary flex items-center border border-arsen-primary w-full md:w-fit px-8 py-3 rounded-lg transition duration-500 hover:bg-arsen-primary hover:text-white hover:shadow-xl"
            >
              Panduan Kegiatan
            </button>
          </div>
          <div
            data-aos="fade-up"
            data-aos-delay="200"
            className="text-md text-left text-gray-400"
          >
            <ul>
              <li className="mb-2">
                <CheckmarkIcon />
                <span className="text-gray-600 align-middle">
                  Mulai 9 Agustus 2024
                </span>
              </li>
              <li className="mb-2">
                <CheckmarkIcon />
                <span className="text-gray-600 align-middle italic">
                  Hybrid
                </span>
              </li>
              <li className="mb-2">
                <CheckmarkIcon />
                <span className="text-gray-600 align-middle">
                  Total hadiah jutaan rupiah
                </span>
              </li>
              <li className="mb-2">
                <CheckmarkIcon />
                <span className="text-gray-600 align-middle">
                  Terbuka untuk Siswa SMA/Sederajat
                </span>
              </li>
              <li className="mb-2">
                <CheckmarkIcon />
                <span className="text-gray-600 align-middle">
                  Terbuka untuk Mahasiswa se-Indonesia
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div
          data-aos="fade-up"
          className="flex mt-8 md:mt-0 md:ml-6 justify-center"
        >
          <ArsenHeroSVG width="320" height="auto" />
        </div>
      </div>
      <div className="flex flex-col md:flex-row gap-4 mx-8 px-0 md:px-8 mt-16 md:mt-8 text-center">
        <div
          data-aos="zoom-in"
          className="p-4 md:p-2 bg-arsen-primary w-full rounded-xl md:rounded-full text-white flex flex-col md:flex-row gap-2"
        >
          <h1 className="font-medium text-2xl w-full">
            Kami Terbuka untuk Sponsorship dan Media Partner!
          </h1>
          <Link
            to="https://wa.me/6285859963963"
            target="_blank"
            className="flex items-center justify-center whitespace-nowrap w-full py-1 px-3 bg-white hover:bg-gray-200 transition duration-300 font-medium text-arsen-primary rounded-lg md:rounded-full md:w-fit"
          >
            Hubungi Kami
          </Link>
        </div>
      </div>
      <div className="flex flex-col md:flex-row gap-4 md:space-y-0 mx-8 px-0 md:px-8 mt-16 md:mt-8 text-center">
        <div data-aos="zoom-in" className="p-4 w-full">
          <p className="text-sm mb-2 font-bold text-gray-600 tracking-widest">
            TENTANG
          </p>
          <h2 className="mb-2 font-bold text-black text-3xl">
            Airlangga Statistics Events
          </h2>
          <div className="mb-2 text-gray-700 text-md text-justify leading-8">
            ARSEN merupakan program kerja Himpunan Mahasiswa Statistika
            Universitas Airlangga. Pada tahun ini, ARSEN mengusung tema{" "}
            <b>
              <i>
                "Data-Driven Social Equity: Uncovering Inequalities and
                Advocating for Justice"
              </i>
            </b>{" "}
            dan terdapat 4 values yang dipegang dalam kegiatan ini yaitu:{" "}
            <b>
              <i>Teamwork, Learning and Knowledge, Competitive dan Creative</i>
            </b>
            . Kegiatan ini diharapkan dapat menjadi wadah untuk berinovasi dan
            mengembangkan kreativitas berdasarkan metode Statistika agar
            generasi muda dapat menggali potensi yang dimiliki Indonesia untuk
            mendukung tercapainya Sustainable Future melalui penerapan
            nilai-nilai Sustainable Development Goals (SDGs) dalam era Society
            5.0.
          </div>
        </div>
        <div data-aos="zoom-in" data-aos-delay="200" className="p-4 w-full">
          <div className="text-sm mb-2 font-bold text-gray-600 tracking-widest">
            TUJUAN
          </div>
          <div className="mb-2 font-bold text-black text-3xl">
            Tujuan Kegiatan
          </div>
          <div className="mb-2 text-gray-700 text-md text-justify leading-8">
            Tujuan program kerja ARSEN dengan tema{" "}
            <b>
              <i>
                "Data-Driven Social Equity: Uncovering Inequalities and
                Advocating for Justice"
              </i>
            </b>{" "}
            adalah:
            <ol className="list-decimal ml-4">
              <li className="mb-2 ">
                <b>Meningkatkan kesadaran</b> akan peran statistika dalam
                pembangunan berkelanjutan di Indonesia.
              </li>
              <li className="mb-2 ">
                <b>Mendorong kolaborasi</b> dan kerja tim antara mahasiswa
                statistika.
              </li>
              <li className="mb-2 ">
                <b>Memperluas pembelajaran dan pengetahuan</b> tentang
                statistika.
              </li>
              <li className="mb-2 ">
                <b>Mendorong kompetisi yang sehat</b> di antara peserta
                kegiatan.
              </li>
              <li className="mb-2 ">
                <b>Mengembangkan kreativitas</b> dalam menerapkan metode
                statistika.
              </li>
            </ol>
          </div>
        </div>
      </div>
      <div className="flex gap-4 mx-8">
        <p className="p-4 text-gray-700 text-md text-justify">
          Dengan mencapai tujuan-tujuan ini, <b>ARSEN</b> berharap dapat menjadi
          wadah yang mendorong inovasi dan kreativitas dalam penerapan
          statistika untuk mendukung tercapainya{" "}
          <i>Sustainable Development Goals</i> (SDGs) dan potensi pembangunan
          berkelanjutan Indonesia.
        </p>
      </div>
      <div
        data-aos="zoom-in"
        className="flex flex-col items-center md:flex-row gap-4 mx-8 px-0 md:px-8 mt-16 md:mt-8 text-center"
      >
        <div className="md:w-1/4">
          <AstroMascotSVG height="200" />
        </div>
        <div className="flex flex-col gap-2 p-4 w-full items-center md:items-start">
          <div className="text-sm font-bold text-gray-600 tracking-widest">
            MASKOT ARSEN
          </div>
          <div className="font-bold text-black text-3xl">ASTRO</div>
          <div className="text-gray-700 text-md text-justify leading-8">
            <span className="font-bold">ASTRO</span>, maskot robot kucing dalam
            perlombaan statistika ARSEN, adalah perwujudan sempurna dari
            teknologi modern dan kelembutan seekor kucing. Dengan kehadirannya
            yang unik dan menghibur, <span className="font-bold">ASTRO</span>{" "}
            mewakili semangat inovasi, kecerdasan buatan, dan kompetisi yang
            bersahabat. <span className="font-bold">ASTRO</span> menginspirasi
            kita untuk melihat masa depan dengan penuh optimisme,
            mengaplikasikan kecerdasan buatan dan statistika agar mengingatkan
            kita bahwa di balik setiap teknologi yang canggih, ada kebutuhan
            untuk menjaga keseimbangan dengan kelembutan dan empati, memastikan
            bahwa setiap langkah maju kita bermanfaat bagi semua makhluk hidup
            di sekitar kita.
          </div>
        </div>
      </div>
      <div
        data-aos="zoom-in"
        className="flex flex-col items-center md:flex-row gap-4 mx-8 px-0 md:px-8 mt-16 md:mt-8 text-center"
      >
        <div className="flex flex-col gap-2 p-4 w-full">
          <div className="text-sm font-bold text-gray-600 tracking-widest uppercase">
            Jingle
          </div>
          <div className="font-bold text-black text-3xl">ARSEN</div>
          <div className="w-full overflow-hidden relative">
            <iframe
              className="w-full aspect-video"
              width="560"
              height="315"
              src="https://www.youtube-nocookie.com/embed/kBx8oJZbPEQ?si=WGKgmKgGRuo32kCo&amp;controls=0"
              title="Jingle ARSEN"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            />
          </div>
        </div>
      </div>
      <div>
        <div
          data-aos="fade-up"
          className="mx-8 flex flex-col text-center font-bold text-black text-3xl"
        >
          Jenis Kegiatan
        </div>
        <div
          data-aos="fade-right"
          className="grid gap-4 text-center mx-8 mt-8 md:grid-cols-2 md:gap-8"
        >
          <div className="flex-auto p-4 bg-white hover:shadow-none shadow-xl transition duration-300 border flex-grow flex-shrink w-full rounded-lg">
            <div className="text-4xl mb-2 font-bold text-arsen-quaternary">Opening</div>
            <div className="text-md mb-2 text-gray-600">
              Ceremony and Talkshow
            </div>
            <div className="flex flex-col space-y-2 md:space-y-0 md:flex-row w-full md:space-x-4">
              <Link
                to="/opening"
                onClick={handleLinkClick}
                className="w-full border border-arsen-quaternary text-base hover:text-white px-4 py-3 rounded-lg transition duration-500 hover:bg-arsen-quaternary"
              >
                Detail Kegiatan
              </Link>
            </div>
          </div>
          <div className="flex-auto p-4 bg-white hover:shadow-none shadow-xl transition duration-300 border flex-grow flex-shrink w-full rounded-lg">
            <div className="text-4xl mb-2 font-bold text-arsen-secondary">
              ASEC
            </div>
            <div className="text-md mb-2 text-gray-600">
              Airlangga Statistics Essay Competition
            </div>
            <div className="flex flex-col space-y-2 md:space-y-0 md:flex-row w-full md:space-x-4">
              <Link
                to="/asec"
                onClick={handleLinkClick}
                className="w-full border border-arsen-secondary text-base text-gray-600 hover:text-white px-4 py-3 rounded-lg transition duration-500 hover:bg-arsen-secondary"
              >
                Detail Kegiatan
              </Link>
            </div>
          </div>
          <div className="flex-auto p-4 bg-white hover:shadow-none shadow-xl transition duration-300 border flex-grow flex-shrink w-full rounded-lg">
            <div className="text-4xl mb-2 font-bold text-arsen-primary">
              ASIC
            </div>
            <div className="text-md mb-2 text-gray-600">
              Airlangga Statistics Infographic Competition
            </div>
            <div className="flex flex-col space-y-2 md:space-y-0 md:flex-row w-full md:space-x-4">
              <Link
                to="/asic"
                onClick={handleLinkClick}
                className="w-full border border-arsen-primary text-base text-gray-600 hover:text-white px-4 py-3 rounded-lg transition duration-500 hover:bg-arsen-primary"
              >
                Detail Kegiatan
              </Link>
            </div>
          </div>
          <div className="flex-auto p-4 bg-white hover:shadow-none shadow-xl transition duration-300 border flex-grow flex-shrink w-full rounded-lg">
            <div className="text-4xl mb-2 font-bold text-arsen-tertiary">
              ASO
            </div>
            <div className="text-md mb-2 text-gray-600">
              Airlangga Statistics Olympiad
            </div>
            <div className="flex flex-col space-y-2 md:space-y-0 md:flex-row w-full md:space-x-4">
              <Link
                to="/aso"
                onClick={handleLinkClick}
                className="border border-arsen-tertiary w-full text-base text-gray-600 hover:text-white px-4 py-3 rounded-lg transition duration-500 hover:bg-arsen-tertiary"
              >
                Detail Kegiatan
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;

import React, { useEffect } from "react";
import AOS from "aos";
import ContactCard from "../../component/ContactCard";
import { Link } from "react-router-dom";
import WodHeroSVG from "../../component/SVGs/WodHeroSVG";
import Image from "../../component/Image";
import { Timeline, TimelineBlock } from "../../component/Timeline";

function SmallCheckmarkSymbol() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-4 w-4 inline-block mr-2"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M5 13l4 4L19 7"
      />
    </svg>
  );
}

function Opening() {
  useEffect(() => {
    AOS.init();
  });

  return (
    <div className="flex flex-col gap-3 text-center md:text-left mx-8 px-0 md:px-8 my-16">
      <div className="flex items-center flex-col md:flex-row gap-3 py-8">
        <div className="flex flex-col gap-3">
          <div data-aos="fade-up">
            <div className="font-bold text-sm tracking-widest text-gray-600 uppercase">
              CABANG KEGIATAN
            </div>
            <div className="text-5xl md:text-6xl font-bold">
              <span className="text-arsen-quaternary">O</span>pening{" "}
            </div>
          </div>
          <div
            data-aos="fade-up"
            data-aos-delay="200"
            className="bg-arsen-quaternary-100 text-arsen-quaternary rounded-full md:w-fit px-4 py-1 font-medium text-sm"
          >
            Ceremony and Talkshow
          </div>
          <div data-aos="fade-up" data-aos-delay="400">
            <div className="font-bold text-sm tracking-widest text-gray-600 uppercase">
              TEMA KEGIATAN
            </div>
            <div className="text-sm md:text-lg leading-6 text-gray-700">
              The Beginning : Unleash The True You, Find The Path to
              Extraordinary
            </div>
          </div>
          <div
            data-aos="fade-up"
            data-aos-delay="600"
            className="flex flex-col md:flex-row gap-2.5 text-md md:text-lg text-center md:text-left text-white mb-8"
          >
            <Link
              to="https://bit.ly/ARSEN2024TalkshowRegistration"
              target="_blank"
              className="bg-arsen-quaternary text-center md:w-fit px-8 py-3 rounded-lg transition duration-500 hover:bg-black hover:shadow-xl"
            >
              Daftar Sekarang!
            </Link>
            <a
              href="#timeline"
              className="text-arsen-quaternary text-center border border-arsen-quaternary md:w-fit px-8 py-3 rounded-lg transition duration-500 hover:bg-black hover:text-white hover:shadow-xl"
            >
              Timeline
            </a>
          </div>
        </div>
        <div
          data-aos="fade-up"
          className="md:ml-4 text-white text-center justify-center"
        >
          <WodHeroSVG width="400" height="auto" />
        </div>
      </div>
      <div
        data-aos="zoom-in"
        className="flex sm:hidden flex-col items-center justify-center gap-2"
      >
        <div className="font-bold text-sm tracking-widest text-gray-600 uppercase">
          POSTER KEGIATAN
        </div>
        <div className="w-full md:w-1/4">
          <Image
            src="/assets/img/talkshow-poster.png"
            alt="Opening Talkshow Poster"
          />
        </div>
      </div>
      <div className="flex flex-col lg:flex-row gap-4 md:gap-12 mb-4">
        <div data-aos="zoom-in" className="flex flex-col gap-3">
          <div className="font-bold text-sm tracking-widest text-gray-600 uppercase">
            TENTANG KEGIATAN
          </div>
          <div className="text-justify text-gray-700 text-md">
            <span className="font-bold">Opening</span>
            <span className="font-normal">
              {" "}
              merupakan sebuah talkshow interaktif bertemakan{" "}
              <b>
                "The Beginning : Unleash The True You, Find The Path to
                Extraordinary"
              </b>{" "}
              akan mengawali perjalanan ARSEN 2024. Acara tersebut bertujuan
              untuk memberikan insight kepada peserta mengenai prospek karir ke
              depan, baik di bidang statistika maupun di luar statistika.
              Peserta talkshow berasal dari masyarakat umum, khususnya pelajar
              SMA dan mahasiswa.
            </span>
          </div>
        </div>
        <div data-aos="zoom-in" className="flex flex-col gap-3 items-center">
          <div className="font-bold text-sm tracking-widest text-gray-600 uppercase">
            BIAYA PENDAFTARAN OPENING
          </div>
          <div className="flex flex-col md:flex-row gap-3 w-full">
            <div className="hover:shadow-xl hover:bg-black group transition duration-500 p-4 px-12 bg-arsen-quaternary-800 rounded-2xl flex-col justify-start items-center gap-2.5 inline-flex">
              <div className="text-center text-white text-sm font-bold uppercase">
                Mahasiswa dan Umum
              </div>
              <div className="pl-4 pr-1 p-1 bg-gray-200 rounded-2xl justify-center items-center gap-2.5 inline-flex">
                <div className="text-center text-gray-600 text-xs font-normal leading-8">
                  Tersedia
                </div>
                <div className="px-2 py-2 bg-arsen-quaternary-800 group-hover:bg-black transition duration-500 rounded-xl justify-center items-center gap-2.5 flex">
                  <div className="text-cente text-white text-xs font-normal">
                    Limited!
                  </div>
                </div>
              </div>
              <div className="self-stretch flex-col justify-center items-center flex">
                <div className="justify-center items-start inline-flex">
                  <div className="text-center text-white text-4xl font-bold">
                    GRATIS!
                  </div>
                </div>
              </div>
              <div className="whitespace-nowrap text-center text-neutral-200 text-sm font-medium leading-normal">
                <SmallCheckmarkSymbol />
                Rekaman Talkshow
              </div>
              <div className="self-stretch text-center text-neutral-200 text-sm font-medium leading-normal">
                <SmallCheckmarkSymbol />
                E-Certificate ber-SKP
              </div>
              <div className="self-stretch text-center text-neutral-200 text-sm font-medium leading-normal">
                <SmallCheckmarkSymbol />
                Insight tentang dunia kerja
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        data-aos="zoom-in"
        className="flex flex-col gap-3 mb-4 text-arsen-quaternary"
      >
        <div className="font-bold text-sm tracking-widest text-gray-600 uppercase">
          Meet Our Speaker
        </div>
        <div className="flex flex-col md:flex-row items-center p-4 border rounded-lg bg-white shadow-lg hover:shadow-none transition duration-200 ease-in gap-2.5">
          <div className="relative w-28 h-28">
            <img
              className="absolute top-0 left-0 w-full h-full object-cover object-top rounded-full"
              src="/assets/img/pemateri/firmansyah.jpeg"
              alt="Mochammad Firmansyah"
            />
          </div>
          <div className="flex flex-col justify-center">
            <h3 className="text-xl md:text-2xl font-bold text-arsen-quaternary-900">
              Mochammad Firmansyah
            </h3>
            <h4 className="text-sm md:text-md">
              Founder & CEO of Ruang Data Indonesia
            </h4>
            <p className="md:text-xl font-medium text-arsen-quaternary-700">
              Finding Passion Beyond Your Major: A Different Career Journey
            </p>
          </div>
        </div>
        <div className="flex flex-col md:flex-row items-center p-4 border rounded-lg bg-white shadow-lg hover:shadow-none transition duration-200 ease-in gap-2.5">
          <div className="relative w-28 h-28">
            <img
              className="absolute top-0 left-0 w-full h-full object-cover object-top rounded-full"
              src="/assets/img/pemateri/fakhri.png"
              alt="Chaerobby Fakhri Fauzan"
            />
          </div>
          <div className="flex flex-col justify-center">
            <h3 className="text-xl md:text-2xl font-bold text-arsen-quaternary-900">
              Chaerobby Fakhri Fauzan P., S.Stat.
            </h3>
            <h4 className="text-sm md:text-md">
              Capital Market Microstructure Research at Indonesia Stock Exchange
            </h4>
            <p className="md:text-xl font-medium text-arsen-quaternary-700">
              From Classroom to Career: Real World Applications of Statistics in
              the Professional Life
            </p>
          </div>
        </div>
      </div>
      <div data-aos="zoom-in" className="flex flex-col gap-3 mb-4">
        <div className="font-bold text-sm tracking-widest text-gray-600 uppercase">
          TIME AND PLACE
        </div>
        <div className="font-bold text-3xl">Sunday, 18 August 2024.</div>
        <div className="font-medium text-2xl text-gray-600">
          Online via{" "}
          <span className="text-blue-800 font-bold">Zoom Meeting</span> Platform
        </div>
      </div>
      <div className="flex flex-col gap-3 mb-4" id="timeline">
        <div
          data-aos="zoom-in"
          className="font-bold text-sm tracking-widest text-gray-600 uppercase"
        >
          TIMELINE OPENING CEREMONY DAN TALKSHOW
        </div>
        <Timeline month={"August"}>
          <TimelineBlock date="9 August 2024">Open Registration</TimelineBlock>
          <TimelineBlock date="17 August 2024">
            Close Registration
          </TimelineBlock>
          <TimelineBlock date="18 August 2024">Talkshow Event</TimelineBlock>
        </Timeline>
      </div>
      <div data-aos="fade-right" className="flex flex-col gap-4 my-16">
        <div className="font-bold text-sm tracking-widest text-gray-600 uppercase">
          What are you waiting for?
        </div>
        <div className="flex-col justify-start items-start flex">
          <div className="text-center md:text-left text-black text-[32px] font-bold">
            Daftarkan dirimu segera!
          </div>
        </div>
        <div className="flex flex-col md:flex-row gap-2.5 text-sm text-center md:text-left text-white mb-8">
          <Link
            to="https://bit.ly/ARSEN2024TalkshowRegistration"
            target="_blank"
            className="bg-arsen-quaternary text-center md:w-fit px-8 py-3 rounded-lg transition duration-500 hover:bg-black hover:shadow-xl"
          >
            Daftar Sekarang!
          </Link>
          <a
            href="#timeline"
            className="text-arsen-quaternary text-center border border-arsen-quaternary md:w-fit px-8 py-3 rounded-lg transition duration-500 hover:bg-black hover:text-white hover:shadow-xl"
          >
            Timeline
          </a>
        </div>
      </div>
      <div data-aos="fade-right">
        <ContactCard event="Opening" />
      </div>
    </div>
  );
}

export default Opening;

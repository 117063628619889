import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./styles.css";
import Home from "./pages/home";
import EventsPage from "./pages/events";
import Asec from "./pages/asec";
import PanduanAsec from "./pages/asec/panduan";
import NotFoundPage from "./pages/notfound";
import Layout from "./scene/layout.js";
import AsicSMA from "./pages/asic-sma";
import Opening from "./pages/opening";
import PanduanAsicSMA from "./pages/asic-sma/panduan";
import Aso from "./pages/aso";
import PanduanAso from "./pages/aso/panduan";
// import ComingSoon from './pages/coming-soon';
import Sitemap from "./component/Sitemap/";
import PanduanWOD from "./pages/opening/panduan";
import AVE from "./pages/ave";
import LinkAVE from "./pages/ave/links";

function App() {
  return (
    <div className="font-sans">
      <BrowserRouter>
        <Routes>
          {/* <Route path='/coming-soon' element={<ComingSoon />} /> */}
          <Route path="/sitemap.json" element={<Sitemap />} />
          <Route element={<Layout />}>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/events" element={<EventsPage />} />
            <Route path="/asec" element={<Asec />} />
            <Route path="/asec/panduan" element={<PanduanAsec />} />
            <Route path="/asic" element={<AsicSMA />} />
            <Route path="/asic/panduan" element={<PanduanAsicSMA />} />
            <Route path="/aso" element={<Aso />} />
            <Route path="/aso/panduan" element={<PanduanAso />} />
            <Route path="/opening" element={<Opening />} />
            <Route path="/opening/panduan" element={<PanduanWOD />} />
            <Route path="/ave" element={<AVE />} />
            <Route path="/ave/links" element={<LinkAVE />} />
            {/*<Route path="/announcement" element={<UnderConstruction />} />*/}
            {/*<Route path="/information" element={<UnderConstruction />} />*/}
            {/*<Route path="/privacy-policy" element={<UnderConstruction />} />*/}
            {/*<Route path="/tnc" element={<UnderConstruction />} />*/}
          </Route>
          <Route path="*" element={<NotFoundPage />}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

import React from 'react';

function NotFoundPage() {
    return (
        <div className='flex flex-col items-center justify-center h-screen gap-4'>
            <div className='flex flex-col'>
                <h1 className='text-4xl font-bold text-gray-800'>404</h1>
                <p className='text-xl text-gray-600'>Page Not Found</p>
            </div>
            <div className='flex flex-col md:flex-row gap-2.5 text-sm text-center md:text-left text-white mb-8'>
                <a
                    href='/'
                    className='bg-[#B16784] md:w-fit px-8 py-3 rounded-lg transition duration-500 hover:bg-black hover:shadow-xl'
                >
                    Kembali ke arsenunair.com
                </a>
            </div>
        </div>
    );
}

export default NotFoundPage;

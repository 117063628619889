import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import ContactCard from "../../component/ContactCard";
import AOS from "aos";
import AsecSVG from "../../component/SVGs/AsecSVG";
import { Timeline, TimelineBlock } from "../../component/Timeline";
import Image from "../../component/Image";
import Rules from "../../component/Rules";

const rules = [
  "Peserta merupakan siswa/i SMA/SMK/MA sederajat di seluruh Indonesia yang dibuktikan dengan Kartu Pelajar.",
  "Peserta merupakan tim yang terdiri dari 2 orang berasal dari SMA yang sama.",
  "Peserta 1 sebagai Ketua Tim wajib mengikuti prosedur pendaftaran hingga selesai.",
  "Peserta yang dianggap sah adalah peserta yang telah mendaftar secara sah dan mengikuti prosedur pendaftaran hingga selesai.",
  "Peserta wajib mengunggah twibbon ARSEN 2024 di Instagram pribadi dan tag ke Instagram @himasta_unair, @arsenunair, serta tag 3 akun teman lainnya menggunakan caption yang telah disediakan. Twibbon dan caption dapat diunduh pada : https://bit.ly/TwibbonASO2024",
  "Peserta yang telah mendaftarkan diri tidak dapat digantikan oleh orang lain.",
  "Peserta wajib mematuhi segala peraturan yang telah ditetapkan oleh panitia dan jika Peserta melanggar peraturan ASO 2024, maka akan diberikan sanksi sesuai peraturan yang berlaku.",
];

function Aso() {
  useEffect(() => {
    AOS.init();
  }, []);
  const handleLinkClick = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div className="flex flex-col gap-3 text-center md:text-left mx-8 px-0 md:px-8 my-16">
      <div className="flex items-center flex-col md:flex-row gap-3 py-8">
        <div className="flex flex-col gap-3">
          <div data-aos="fade-up">
            <div className="font-bold text-sm tracking-widest text-arsen-tertiary-500 uppercase">
              CABANG KEGIATAN
            </div>
            <div className="text-5xl md:text-6xl font-bold">
              <span className="text-arsen-tertiary">A</span>irlangga{" "}
              <span className="text-arsen-tertiary">S</span>tatistics{" "}
              <span className="text-arsen-tertiary">O</span>lympiad{" "}
            </div>
          </div>
          <div
            data-aos="fade-up"
            data-aos-delay="100"
            className="bg-arsen-tertiary-100 text-arsen-tertiary rounded-full md:w-fit px-4 py-1 font-medium text-sm"
          >
            Tingkat Siswa se-Indonesia
          </div>
          <div data-aos="fade-up" data-aos-delay="150">
            <div className="font-bold text-sm tracking-widest text-arsen-tertiary-500 uppercase">
              TEMA KOMPETISI
            </div>
            <div className="text-sm md:text-lg leading-6 text-gray-700">
              Utilizing Statistical Critical Thinking & Problem Solving Skills
              in Data Driven Society
            </div>
          </div>
          <div
            data-aos="fade-up"
            data-aos-delay="200"
            className="flex flex-col md:flex-row gap-2.5 text-md md:text-lg text-center md:text-left text-white mb-8"
          >
            <Link
              to="https://bit.ly/FormPendaftaranASOBatch2"
              target="_blank"
              className="bg-arsen-tertiary md:w-fit text-center px-8 py-3 rounded-lg transition duration-500 hover:bg-black hover:shadow-xl"
            >
              Daftar Sekarang!
            </Link>
            <Link
              to="/aso/panduan/"
              onClick={handleLinkClick}
              className="text-center text-arsen-tertiary border border-arsen-tertiary md:w-fit px-8 py-3 rounded-lg transition duration-500 hover:bg-black hover:text-white hover:shadow-xl"
            >
              Panduan Kegiatan
            </Link>
            <a
              href="#timeline"
              className="text-center text-arsen-tertiary border border-arsen-tertiary md:w-fit px-8 py-3 rounded-lg transition duration-500 hover:bg-black hover:text-white hover:shadow-xl"
            >
              Timeline Kompetisi
            </a>
          </div>
        </div>
        <div
          data-aos="fade-up"
          className="md:ml-4 text-white text-center justify-center"
        >
          <AsecSVG width="400" height="auto" />
        </div>
      </div>
      <div
        data-aos="zoom-in"
        className="flex sm:hidden flex-col items-center justify-center gap-2"
      >
        <div className="font-bold text-sm tracking-widest text-gray-600 uppercase">
          POSTER KEGIATAN
        </div>
        <div className="w-full md:w-1/4">
          <Image
            src="/assets/img/poster/ASO BATCH 2 (1) (Medium).png"
            alt="ASO Poster"
          />
        </div>
      </div>
      <div className="flex flex-col md:flex-row gap-4 md:gap-12">
        <div data-aos="zoom-in" className="flex flex-col gap-3">
          <div className="font-bold text-sm tracking-widest text-arsen-tertiary-500 uppercase">
            TENTANG KOMPETISI
          </div>
          <p className="text-justify text-gray-700 text-md">
            <span className="font-bold">Airlangga Statistic Olympiad</span>
            <span className="font-normal">
              {" "}
              (ASO) pada kegiatan ARSEN 2024 merupakan ajang bergengsi yang
              ditujukan kepada siswa/siswi SMA Sederajat yang memiliki minat dan
              potensi dalam bidang ilmu statistika. Dengan mengangkat tema{" "}
              <b>
                "Utilizing Statistical Critical Thinking & Problem Solving
                Skills in Data Driven Society"
              </b>
              , kegiatan ini bertujuan untuk memperluas pengetahuan serta
              meningkatkan kemampuan analitis siswa/siswi SMA Sederajat dalam
              mengaplikasikan konsep-konsep statistika untuk menyelesaikan
              soal-soal seputar analisis data, interpretasi hasil, dan
              pengambilan keputusan berbasis data.
            </span>
          </p>
        </div>
        <div data-aos="zoom-in" className="flex flex-col gap-3">
          <div className="font-bold text-sm tracking-widest text-arsen-tertiary-500 uppercase">
            BIAYA PENDAFTARAN ASEC
          </div>
          <div className="flex flex-col md:flex-row gap-3">
            <div className="hover:shadow-xl hover:bg-black group transition duration-500 p-4 px-12 bg-arsen-tertiary-700 rounded-2xl flex-col justify-start items-center gap-2.5 inline-flex">
              <div className="pl-4 pr-1 p-1 bg-gray-200 rounded-2xl justify-center items-center gap-2.5 inline-flex">
                <div className="text-center text-arsen-tertiary-500 text-xs font-normal leading-8">
                  First Batch
                </div>
                <div className="px-2 py-2 bg-arsen-tertiary-700 group-hover:bg-black transition duration-500 rounded-xl justify-center items-center gap-2.5 flex">
                  <div className="text-cente text-white text-xs font-normal">
                    Soon!
                  </div>
                </div>
              </div>
              <div className="self-stretch flex-col justify-center items-center flex">
                <div className="text-center text-neutral-200 text-sm font-normal line-through leading-normal">
                  Rp60.000
                </div>
                <div className="justify-center items-start inline-flex">
                  <div className="text-center text-white text-sm font-medium leading-normal">
                    Rp
                  </div>
                  <div className="text-center text-white text-4xl font-bold">
                    40.000
                  </div>
                  <div className="w-[39px] self-stretch justify-center items-end gap-2.5 flex">
                    <div className="text-center text-neutral-200 text-xs font-medium leading-[21px]">
                      /team
                    </div>
                  </div>
                </div>
              </div>
              <div className="px-4 rounded-full border border-gray-200 justify-center items-center gap-1.5 inline-flex">
                <div className="text-center text-white text-xs font-bold leading-[21px]">
                  %
                </div>
                <div className="text-center text-white text-xs font-normal leading-[21px]">
                  -Rp20.000 OFF!
                </div>
              </div>
              <div className="self-stretch text-center text-neutral-200 text-sm font-medium leading-normal">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4 inline-block mr-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M5 13l4 4L19 7"
                  />
                </svg>
                2 Members / Team
              </div>
              <div className="self-stretch text-center text-neutral-200 text-sm font-medium leading-normal">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4 inline-block mr-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M5 13l4 4L19 7"
                  />
                </svg>
                National E-Certificate
              </div>
            </div>
            <div className="hover:shadow-xl hover:bg-black group transition duration-500 p-4 px-12 bg-arsen-tertiary-800 rounded-2xl flex-col justify-start items-center gap-2.5 inline-flex">
              <div className="pl-4 pr-1 p-1 bg-gray-200 rounded-2xl justify-center items-center gap-2.5 flex">
                <div className="text-center text-arsen-tertiary-500 text-xs font-normal leading-8">
                  Last Batch
                </div>
                <div className="px-2 py-2 bg-arsen-tertiary-800 group-hover:bg-black transition duration-500 rounded-xl justify-center items-center gap-2.5 flex">
                  <div className="text-cente text-white text-xs font-normal">
                    Soon!
                  </div>
                </div>
              </div>
              <div className="self-stretch flex-col justify-center items-center flex">
                <div className="justify-center items-start inline-flex">
                  <div className="text-center text-white text-sm font-medium leading-normal">
                    Rp
                  </div>
                  <div className="text-center text-white text-4xl font-bold">
                    60.000
                  </div>
                  <div className="w-[39px] self-stretch justify-center items-end gap-2.5 flex">
                    <div className="text-center text-neutral-200 text-xs font-medium leading-[21px]">
                      /team
                    </div>
                  </div>
                </div>
              </div>
              <div className="px-4 rounded-full border border-gray-200 justify-center items-center gap-1.5 inline-flex">
                <div className="text-center text-white text-xs font-normal leading-[21px]">
                  Normal Price
                </div>
              </div>
              <div className="self-stretch text-center text-neutral-200 text-sm font-medium leading-normal">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4 inline-block mr-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M5 13l4 4L19 7"
                  />
                </svg>
                2 Members / Team
              </div>
              <div className="self-stretch text-center text-neutral-200 text-sm font-medium leading-normal">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4 inline-block mr-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M5 13l4 4L19 7"
                  />
                </svg>
                National E-Certificate
              </div>
            </div>
          </div>
        </div>
      </div>
      <div data-aos="zoom-in" className="flex flex-col gap-3 mb-4">
        <div className="font-bold text-sm tracking-widest text-arsen-tertiary-500 uppercase">
          Competition Prize
        </div>
        <div className="grid gap-4 md:grid-cols-2">
          <div className="flex flex-col md:flex-row p-4 border rounded-lg bg-white shadow-lg hover:shadow-none transition duration-200 ease-in gap-2.5">
            <div className="flex flex-col">
              <div className="text-lg font-bold">1st, 2nd, and 3rd Winner</div>
              <div className="ordinal text-md">
                Coaching Cash, Faculty Tour, & National E-Certificate
              </div>
            </div>
          </div>
          <div className="flex flex-col md:flex-row p-4 border rounded-lg bg-white shadow-lg hover:shadow-none transition duration-200 ease-in gap-2.5">
            <div className="flex flex-col">
              <div className="text-lg font-bold">Best Speaker</div>
              <div className="ordinal text-md">
                Coaching Cash & National E-Certificate
              </div>
            </div>
          </div>
        </div>
      </div>
      <div data-aos="zoom-in" className="flex flex-col gap-3 mb-4">
        <div className="font-bold text-sm tracking-widest text-arsen-tertiary-500 uppercase">
          Syarat dan Ketentuan
        </div>
        <Rules rules={rules} />
      </div>
      <div data-aos="zoom-in" className="flex flex-col gap-3" id="timeline">
        <div className="font-bold text-sm tracking-widest text-arsen-tertiary-500 uppercase">
          Timeline for Airlangga Statistics Olympiad
        </div>

        <Timeline month="August">
          <TimelineBlock date="9 August 2024">
            Open Registration Batch 1
          </TimelineBlock>
          <TimelineBlock date="30 August 2024">
            Close Registration Batch 1
          </TimelineBlock>
          <TimelineBlock date="31 August 2024">
            Open Registration Batch 2
          </TimelineBlock>
        </Timeline>

        <Timeline month="September">
          <TimelineBlock date="9 September 2024">
            Close Registration Batch 2
          </TimelineBlock>
          <TimelineBlock date="17 September 2024">
            Technical Meeting for Preliminary Round
          </TimelineBlock>
          <TimelineBlock date="21 September 2024">
            Preliminary Round
          </TimelineBlock>
          <TimelineBlock date="23 September 2024">
            Semi-finalist Announcement
          </TimelineBlock>
          <TimelineBlock date="23 September 2024">
            Start of Semi-finalist Re-registration
          </TimelineBlock>
          <TimelineBlock date="26 September 2024">
            Last day of Semi-finalist Re-registration
          </TimelineBlock>
          <TimelineBlock date="26 September 2024">
            Technical Meeting for Semi-finalist Round
          </TimelineBlock>
          <TimelineBlock date="28 September 2024">
            Semi-finalist Round
          </TimelineBlock>
          <TimelineBlock date="30 September 2024">
            Start of Judgement Period
          </TimelineBlock>
        </Timeline>

        <Timeline month="October">
          <TimelineBlock date="8 October 2024">
            Last day of Judgement Period
          </TimelineBlock>
          <TimelineBlock date="14 October 2024">
            Finalist Announcement
          </TimelineBlock>
          <TimelineBlock date="14 October 2024">
            Start of Re-registration for Finalists
          </TimelineBlock>
          <TimelineBlock date="21 October 2024">
            Last day of Re-registration for Finalists
          </TimelineBlock>
          <TimelineBlock date="23 October 2024">
            Technical Meeting for Finalist Round
          </TimelineBlock>
        </Timeline>

        <Timeline month="November">
          <TimelineBlock date="3 November 2024">
            Finalist Round & Closing Ceremony
          </TimelineBlock>
        </Timeline>
      </div>

      <div data-aos="fade-right" className="flex flex-col gap-4 my-16">
        <div className="font-bold text-sm tracking-widest text-arsen-tertiary-500 uppercase uppercase">
          Informasi Lainnya
        </div>
        <div className="flex-col justify-start md:items-start flex">
          <div className="text-center md:text-left text-black text-[32px] font-bold">
            Pengumpulan Karya ASO 2024
          </div>
        </div>
        <div className="flex flex-col md:flex-row gap-2.5 text-sm text-center md:text-left text-white mb-8">
          <Link
            to="https://bit.ly/FormPengumpulanKaryaASO2024"
            target="_blank"
            className="bg-arsen-tertiary md:w-fit text-center px-8 py-3 rounded-lg transition duration-500 hover:bg-black hover:shadow-xl"
          >
            Pengumpulan Karya
          </Link>
          <Link
            to="/aso/panduan/"
            onClick={handleLinkClick}
            className="text-center text-arsen-tertiary border border-arsen-tertiary md:w-fit px-8 py-3 rounded-lg transition duration-500 hover:bg-black hover:text-white hover:shadow-xl"
          >
            Panduan Kegiatan
          </Link>
          <a
            href="#timeline"
            className="text-arsen-tertiary border border-arsen-tertiary md:w-fit px-8 py-3 rounded-lg transition duration-500 hover:bg-black hover:text-white hover:shadow-xl"
          >
            Timeline Kompetisi
          </a>
        </div>
      </div>
      <div data-aos="fade-right">
        <ContactCard event="ASO" />
      </div>
    </div>
  );
}

export default Aso;

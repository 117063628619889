import React, { useEffect } from 'react';
import AOS from 'aos';
import IdeaMapSVG from '../../../component/SVGs/IdeaMapSVG';
import LadyWithCatSVG from '../../../component/SVGs/LadyWithCatSVG';
import OriginalPaperSVG from '../../../component/SVGs/OriginalPaperSVG';
import WordIconSVG from '../../../component/SVGs/WordIconSVG';
import { Link } from 'react-router-dom';
import PngIconSVG from '../../../component/SVGs/PngIconSVG';

function PanduanWOD() {
    useEffect(() => {
        AOS.init();
    }, []);
    return (
        <div className='my-16 mx-8 justify-center text-center md:text-left'>
            <div className='flex flex-col mb-8 gap-4'>
                <div>
                    <div
                        data-aos='fade-up'
                        className='uppercase font-bold text-gray-600 tracking-widest'
                    >
                        Panduan Kegiatan
                    </div>
                    <div
                        data-aos='fade-up'
                        data-aos-delay='100'
                        className='text-5xl md:text-6xl font-bold'
                    >
                        <span className='text-[#B16784]'>W</span>orkshop{' '}
                        <span className='text-[#B16784]'>O</span>lah{' '}
                        <span className='text-[#B16784]'>D</span>ata
                    </div>
                </div>
                <div
                    data-aos='fade-up'
                    data-aos-delay='200'
                    className='bg-gray-200 rounded-full text-gray-600 md:w-fit px-4 py-1 font-medium text-sm'
                >
                    Untuk Umum/Mahasiswa
                </div>
            </div>
            <div
                data-aos='fade-up'
                data-aos-delay='300'
                className='grid grid-cols-1 md:grid-cols-3 py-8 gap-4'
            >
                <Link
                    to='http://bit.ly/GuidebookASIC_Siswa2023'
                    target='_blank'
                    className='bg-white border rounded-lg shadow-md p-4 hover:shadow-none transition duration-500 ease-out'
                >
                    <div className='flex p-4 justify-center'>
                        <IdeaMapSVG height='100' width='auto' />
                    </div>
                    <h2 className='text-xl font-semibold mb-2 uppercase'>
                        Guideline
                    </h2>
                    <p className='text-gray-500 mb-4'>
                        Buku panduan untuk kompetisi
                    </p>
                </Link>
                <Link
                    to='https://bit.ly/TwibbonSiswaASIC2023'
                    target='_blank'
                    className='bg-white border rounded-lg shadow-md p-4 hover:shadow-none transition duration-500 ease-out'
                >
                    <div className='flex p-4 justify-center'>
                        <LadyWithCatSVG height='100' width='auto' />
                    </div>
                    <h2 className='text-xl font-semibold mb-2 uppercase'>
                        Twibbon
                    </h2>
                    <p className='text-gray-500 mb-4'>
                        Meriahkan kompetisi dengan mengunggah twibbon!
                    </p>
                </Link>
                <Link
                    to='https://bit.ly/SuratOriginalitasSiswaASIC2023'
                    target='_blank'
                    className='bg-white border rounded-lg shadow-md p-4 hover:shadow-none transition duration-500 ease-out'
                >
                    <div className='flex p-4 justify-center'>
                        <OriginalPaperSVG height='100' width='auto' />
                    </div>
                    <h2 className='text-xl font-semibold mb-2 uppercase'>
                        Surat Orisinalitas
                    </h2>
                    <p className='text-gray-500 mb-4'>
                        Surat pernyataan orisinalitas karya
                    </p>
                </Link>
                <Link
                    to='https://bit.ly/TemplateDeskripsiKaryaSiswaASIC2023'
                    target='_blank'
                    className='bg-white border rounded-lg shadow-md p-4 hover:shadow-none transition duration-500 ease-out'
                >
                    <div className='flex p-4 justify-center'>
                        <WordIconSVG width='auto' height='100' />
                    </div>
                    <h2 className='text-xl font-semibold mb-2 uppercase'>
                        Template Deskripsi Karya
                    </h2>
                    <p className='text-gray-500 mb-4'>
                        Template untuk penjelasan karya infografis
                    </p>
                </Link>
                <Link
                    to='https://bit.ly/WatermarkASIC2023'
                    target='_blank'
                    className='bg-white border rounded-lg shadow-md p-4 hover:shadow-none transition duration-500 ease-out'
                >
                    <div className='flex p-4 justify-center'>
                        <PngIconSVG width='auto' height='100' />
                    </div>
                    <h2 className='text-xl font-semibold mb-2 uppercase'>
                        Watermark Karya
                    </h2>
                    <p className='text-gray-500 mb-4'>
                        Watermark untuk karya infografis ASIC
                    </p>
                </Link>
            </div>
        </div>
    );
}

export default PanduanWOD;

import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Arsen2024LogoOnlySVG } from "../SVGs/ArsenLogo2024SVG";

function NavigationBar() {
  const handleLinkClick = () => {
    window.scrollTo(0, 0);
  };

  const [navigationMenu, setNavigationMenu] = useState([
    {
      name: "Home",
      link: "/home",
      active: true,
    },
    {
      name: "Events",
      link: "/events",
      active: false,
    },
    {
      name: "AVE",
      link: "/ave",
      active: false,
    },
    // {
    //     name: 'Announcement',
    //     link: '/announcement',
    //     active: false,
    // },
    // {
    //     name: 'Information',
    //     link: '/information',
    //     active: false,
    // },
  ]);

  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const location = useLocation();

  useEffect(() => {
    const updatedMenu = navigationMenu.map((menuItem) => ({
      ...menuItem,
      active: location.pathname.startsWith(menuItem.link),
    }));
    setNavigationMenu(updatedMenu);
  }, [navigationMenu, location]);

  return (
    <div className="fixed top-0 z-50 w-full shadow-sm">
      <div className="flex flex-row justify-between items-center w-full h-16 px-4 sm:px-8 text-arsen-primary-900 bg-white bg-opacity-50 backdrop-filter backdrop-blur-lg">
        <Link to="/" className="flex flex-row items-center space-x-2">
          <div>
            <Arsen2024LogoOnlySVG sideLength="32" />
          </div>
          <div className="sm:text-xl font-bold">ARSΣN UNAIR</div>
        </Link>
        <div className="hidden flex-row items-center sm:flex text-sm">
          <div className="flex flex-row items-center space-x-2">
            {navigationMenu.map((item, index) => (
              <Link
                key={index}
                to={item.link}
                className={`px-4 py-2 rounded-lg ${
                  item.active
                    ? "bg-arsen-primary text-white"
                    : "text-arsen-primary"
                } hover:bg-black hover:text-white hover:shadow-lg transition duration-500`}
                onClick={() => {
                  const updatedMenu = navigationMenu.map((menuItem, idx) => ({
                    ...menuItem,
                    active: idx === index,
                  }));
                  setNavigationMenu(updatedMenu);
                  handleLinkClick();
                }}
              >
                {item.name}
              </Link>
            ))}
          </div>
        </div>
        <div className="sm:hidden">
          <button
            type="button"
            className={`text-arsen-primary px-2 py-2 focus:outline-none ${
              menuOpen ? "bg-arsen-primary-900 rounded-lg text-white" : ""
            }`}
            onClick={toggleMenu}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 6h16M4 12h16m-7 6h7"
              />
            </svg>
          </button>
        </div>
      </div>
      <div
        id="mobile-menu"
        className={`${
          menuOpen ? "block" : "hidden sm:block"
        } sm:hidden text-sm flex z-10 px-5 py-4 bg-arsen-primary-900 flex-col justify-start items-center gap-2.5`}
      >
        {navigationMenu.map((item, index) => (
          <Link
            key={index}
            to={item.link}
            className={`self-stretch px-4 py-4 text-white rounded-lg justify-start items-center gap-2.5 inline-flex ${
              item.active
                ? "bg-arsen-primary-600 font-bold"
                : "text-arsen-primary"
            } hover:bg-black hover:text-white hover:shadow-lg transition duration-500`}
            onClick={() => {
              const updatedMenu = navigationMenu.map((menuItem, idx) => ({
                ...menuItem,
                active: idx === index,
              }));
              setNavigationMenu(updatedMenu);
              setMenuOpen(false);
              handleLinkClick();
            }}
          >
            <div className="text-white">{item.name}</div>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default NavigationBar;

import React from "react";
import SolidRectangleSVG from "../SVGs/SolidRectangleSVG";

export default function Rules({ rules }) {
  return (
    <div className="flex text-md flex-col text-justify gap-2.5">
      {rules.map((rule, index) => (
        <div key={index} className="flex flex-row">
          <SolidRectangleSVG />
          <div className="ml-2">{rule}</div>
        </div>
      ))}
    </div>
  );
}

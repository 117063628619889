import React from 'react';

function SolidRectangleSVG() {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 100 100'
            fill='currentColor'
            className='w-3 h-3 mt-2 flex-shrink-0'
        >
            <rect width='50' height='50' />
        </svg>
    );
}

export default SolidRectangleSVG;

import React from 'react';

function IdeaMapSVG({ width = '549.23', height = '475.22' }) {
    return (
        <svg
            data-name='Layer 1'
            width={width}
            height={height}
            viewBox='0 0 549.23 475.22'
            id='maps'
        >
            <defs>
                <linearGradient
                    id='a'
                    x1='286.26'
                    x2='281.23'
                    y1='137.54'
                    y2='879.17'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop offset='0' stop-opacity='0'></stop>
                    <stop offset='.97'></stop>
                </linearGradient>
                <linearGradient
                    id='b'
                    x1='319.69'
                    x2='256.5'
                    y1='111.43'
                    y2='415.43'
                ></linearGradient>
                <linearGradient
                    id='c'
                    x1='33.08'
                    x2='50.54'
                    y1='382.35'
                    y2='576.06'
                ></linearGradient>
                <linearGradient
                    id='d'
                    x1='521.41'
                    x2='533.8'
                    y1='360.43'
                    y2='872.87'
                ></linearGradient>
                <linearGradient
                    id='e'
                    x1='150.1'
                    x2='142.22'
                    y1='458.09'
                    y2='49.26'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop offset='0' stop-opacity='0'></stop>
                    <stop offset='.83' stop-opacity='.47'></stop>
                    <stop offset='.97'></stop>
                </linearGradient>
                <linearGradient
                    id='f'
                    x1='356.64'
                    x2='343.12'
                    y1='176.75'
                    y2='712.27'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop offset='0' stop-opacity='0'></stop>
                    <stop offset='.9' stop-opacity='.83'></stop>
                    <stop offset='.97'></stop>
                </linearGradient>
            </defs>
            <path
                d='M543.03 291.1a23.94 23.94 0 0 0-3-2.77c2-79.14-4.28-243.5-4.55-250.61L534.03 0 340.25 103.49 198.92 21.65l-11.72 6.51c-16.56 9.2-162 90-169.07 94.13l-11.87 6.89.2 15.32c.57 47.6 3.4 327 3.43 330.73 0 0 56.29-33.22 65.31-38.64 15-9.05 35.82-21.69 61.75-37.56 30.59-18.74 61.61-37.87 76.94-47.33 55.91 30.58 115 63.44 124.31 69.58a24.2 24.2 0 0 0 15.78 5.77c7 0 11.55 0 95.76-40.88 40.4-19.6 80.76-39.81 85.46-42.18 7.84-4 12.79-11.7 13.59-21.23.18-2.18.17-4.31.17-6.57s0-4.72.18-6.89a23.85 23.85 0 0 0-6.11-18.2ZM355.46 394.27c-6.73-4.38-23.65-14.16-69.07-39.32-34.37-19-68.86-37.85-69.2-38l-4.34-2.37-4.2 2.6c-56.06 34.65-136.9 84.29-166.81 101.9-.61-53.05-2.82-237.52-3.22-271.52 24-13.46 136.65-76.06 160-89l141 81.72 164.24-87.74c1.67 47.38 6.81 205.17 3.06 257.47a8.69 8.69 0 0 0 6.45 9c-44.91 22.51-138.99 68.69-157.91 75.26Z'
                opacity='.24'
            ></path>
            <path
                fill='#68e1fd'
                d='M30.06 134.65c7-4.12 168.61-93.87 168.61-93.87s143.79 78.54 143.88 72.92 172.08-81.23 169.31-83.05c-2.38-1.59 8 209.3 3.54 272-.1 1.37 10.31-4.69 10.2-3.47-.72 7.77.32 15.07-1.06 15.77-9.51 4.8-168.28 84.22-171 80.38s-140.37-78.9-140.37-78.9S33.51 427.44 33.51 425.34s-3.45-290.69-3.45-290.69Z'
            ></path>
            <path
                fill='url(#a)'
                d='m33.53 491.64-.05-7.59c0-3.22-3.42-287.74-3.45-290.61L30 189l3.8-2.22c7-4.11 162.16-90.29 168.76-94l3.75-2.08 141.18 81.8 179-95.5.51 12.05c.31 8.09 7.17 188.74 4.16 261.25a9.07 9.07 0 0 1 1.79-.19 7.83 7.83 0 0 1 5.78 2.52 7.71 7.71 0 0 1 2 5.9c-.27 2.93-.26 5.84-.25 8.41 0 5.61 0 10.91-4.94 13.42-4.69 2.37-45 22.54-85.22 42.07-80.89 39.25-85.81 39.26-88.75 39.26a8 8 0 0 1-5.77-2.32c-7.31-5.41-84.64-48.14-134.82-75.54-11.59 7.16-48.27 29.8-84.84 52.2-25.84 15.84-46.58 28.45-61.63 37.5-30.12 18.11-30.68 18.11-33.36 18.11Z'
                transform='translate(-7.54 -54.35)'
            ></path>
            <path
                fill='#fff'
                d='m356.82 191.59 156.55-79.66-1.54-79.66-152.15 81.26-2.86 78.06zM32.98 229.64l163.29-74v70.78L32.98 316.99v-87.35z'
                opacity='.13'
            ></path>
            <path
                fill='#fff'
                d='M536.72 290.47a15.17 15.17 0 0 0-5.37-3.78c2.37-76.85-4.1-247.14-4.4-255l-.93-24.15-21.32 11.38-136.6 73-28.05 15-9.47-5.49-33.82-19.65-38.82-22.49-51.71-30-7.43-4.31-7.5 4.17c-6.61 3.67-161.8 89.88-168.91 94l-7.6 4.5.11 8.8c0 2.87 3.42 286.52 3.45 290.58a15.18 15.18 0 0 0 15.17 15.1c4.67 0 7.59-1.69 16.26-6.72 4.94-2.87 12-7.06 21-12.47 15.07-9.06 35.82-21.69 61.69-37.53 33.27-20.38 66.63-41 81.12-49.91 48.71 26.62 120.42 66.26 129.78 72.67a15.59 15.59 0 0 0 10.57 4c4.94 0 9.6 0 92-40 40.25-19.54 80.63-39.75 85.34-42.13 3-1.52 8.2-5.42 9-14.35.15-1.81.14-3.68.14-5.84 0-2.4 0-5.13.22-7.69a15.3 15.3 0 0 0-3.92-11.69Zm-12.21 26.07c-9.28 4.68-160.86 80.52-170.57 80.52a.56.56 0 0 1-.45-.14c-2.74-3.84-140.37-78.9-140.37-78.9s-176.28 109-179.6 109c0-2.1-3.45-290.7-3.45-290.7 7-4.12 168.61-93.87 168.61-93.87l49.19 28.5 32.81 19 50.89 29.49 8.22 4.76 28.65-15.3 143.43-76.6s8 209.3 3.54 272c0 .14.09.2.28.2 1.48 0 8.36-3.83 9.7-3.83.15 0 .23.05.22.16-.76 7.71.28 15.01-1.1 15.71Z'
            ></path>
            <path
                fill='url(#b)'
                d='m375.64 146.23-28.05 15-9.47-5.49a30.59 30.59 0 1 1-33.82-19.59l-38.82-22.49a63.54 63.54 0 0 0-10.11 11.62 78.4 78.4 0 0 0-8.26 15.94c-10.16 26.31-7.14 55.91 7.28 80.16l40.79 85.37s74.82-81.38 80.76-143.53a70.63 70.63 0 0 0-.3-16.99Z'
                transform='translate(-7.54 -54.35)'
            ></path>
            <path
                fill='#68e1fd'
                d='M4.04 340.42c-4.31 7.18-6.46 17.81.21 22.86 2.72 2.05 6.43 2.7 8.7 5.25 3.4 3.82 1.87 9.88-.64 14.35s-5.91 8.87-5.78 14c.23 9 10.56 13.71 19.17 16.31 2.45.74 5 1.49 7.53 1s4.71-2.17 6.65-3.91a70.19 70.19 0 0 0 17.58-24.63c1.63-3.85 2.91-8.23 1.4-12.13-1.2-3.11-4-5.3-6-7.9a21.43 21.43 0 0 1-2-23c2-3.71 5.1-6.73 7.3-10.34 8.61-14.08-12.72-19.28-17.49-29.17-3.59-7.44-.47-14.08-12.89-16.47-10.95-2.11-15.78 3-16.95 13.55-.81 7.39 3.52 14 2.7 21.26s-5.93 13.03-9.49 18.97Z'
            ></path>
            <path
                fill='#68e1fd'
                d='m31.75 416.14 2 33.42c.23 3.93 1.61 9 5.55 9.13 3.67.09 5.47-4.47 5.69-8.14.69-11.56-3.82-22.69-7.23-33.75s-5.66-23.43-.76-33.91c1.38-2.94 3.32-6.28 1.85-9.18a54.22 54.22 0 0 1-7.12 15.84l-5-31.74c-.55-3.52-1.42-7.5-4.53-9.24l4 34.07-4.85-.89c-2.54-.46 6.59 13.68 6.89 14.39 2.66 6.25 3.11 13.3 3.51 20ZM469.8 338.77c1.3-12.29 14.66-19.31 20.66-30.12 1.07-1.9 1.93-4.1 1.47-6.24-.61-2.82-3.25-4.63-5.4-6.54-10-8.9-11.34-25.9-2.84-36.25 5.74-7 15.28-11.4 17.6-20.13 1.25-4.69.05-9.63-.42-14.46-.6-6.11 0-12.53 3.12-17.85s8.91-9.28 15-8.93c9.55.54 15.85 11 16.14 20.52s-3.53 18.69-5.7 28c-.78 3.33-1.34 6.84-.47 10.14 1.3 5 5.48 8.5 8.68 12.48 7.56 9.42 9.13 25-.23 32.62-5.25 4.28-13.74 6.5-14.46 13.24-.45 4.14 2.64 7.88 6.12 10.16s7.53 3.68 10.79 6.27c8.2 6.51 8.75 20.53 1.08 27.67-2.83 2.62-6.41 4.29-9.34 6.79s-5.25 6.38-4.21 10.08c.4 1.42 1.25 2.67 1.59 4.1.74 3.12-1.22 6.38-3.88 8.15s-5.92 2.4-9.07 3c-7.14 1.32-15.79 2.83-22-2.06s-3.45-9.23-5.74-15.46c-4.58-12.54-20.13-19.63-18.49-35.18Z'
            ></path>
            <path
                fill='#68e1fd'
                d='M508 340.65a27.47 27.47 0 0 1-.25-3.19 1.62 1.62 0 0 1 3.23-.09l1.22 16.8c.15 2 1.18 4.66 3.11 4.15a35.69 35.69 0 0 1 3.43-11.6 1.52 1.52 0 0 1 2.87.89c-.46 2.94-1.88 5.91-3 8.74-10.77 27.56 7.85 60-2.39 87.73-.82 1.13-2.31-.66-2.5-2a270.73 270.73 0 0 1-1.86-55.43c1.07-15.46-1.83-30.69-3.86-46Z'
            ></path>
            <path
                fill='url(#c)'
                d='m39.29 470.49 2 33.42c.23 3.93 1.61 9 5.55 9.13 3.67.09 5.47-4.47 5.69-8.14.69-11.56-3.82-22.69-7.23-33.75s-5.66-23.43-.76-33.91c1.38-2.94 3.32-6.28 1.85-9.18a54.22 54.22 0 0 1-7.12 15.84l-5-31.74c-.55-3.52-1.42-7.5-4.53-9.24l4 34.07-4.85-.89c-2.54-.46 6.59 13.68 6.89 14.39 2.66 6.25 3.11 13.3 3.51 20Z'
                transform='translate(-7.54 -54.35)'
            ></path>
            <path
                fill='url(#d)'
                d='M515.54 395a27.47 27.47 0 0 1-.25-3.19 1.62 1.62 0 0 1 3.23-.09l1.22 16.8c.15 2 1.18 4.66 3.11 4.15a35.43 35.43 0 0 1 3.83-12.37 1.37 1.37 0 0 1 2.58.77c-.29 3.22-1.88 6.52-3.1 9.63-10.77 27.56 7.85 60-2.39 87.73-.82 1.13-2.31-.66-2.5-2A270.73 270.73 0 0 1 519.4 441c1.07-15.46-1.83-30.69-3.86-46Z'
                transform='translate(-7.54 -54.35)'
            ></path>
            <path
                fill='#68e1fd'
                d='M353.11 77.91c-7.91-23.06-25.34-43.49-62.4-42.33-37.77 1.18-56 22-64.66 44.33-10.16 26.31-7.14 55.92 7.28 80.16l54.31 92.31 49.35-86.73c12.02-24.73 26.41-57.79 16.12-87.74Zm-64.76 57.67A30.59 30.59 0 1 1 318.94 105a30.58 30.58 0 0 1-30.59 30.58Z'
            ></path>
            <path
                fill='#fff'
                d='M252.46 79.91c10.07-19.11 23.35-39 54-43.41a82.2 82.2 0 0 0-15.67-.92c-37.77 1.18-56 22-64.66 44.33-10.16 26.31-7.14 55.92 7.28 80.16l54.31 92.31s-64.63-116.8-35.26-172.47Z'
                opacity='.2'
            ></path>
            <path
                fill='#fff'
                d='M284.13 105a30.62 30.62 0 0 1 17.4-27.62 30.59 30.59 0 1 0 0 55.23 30.6 30.6 0 0 1-17.4-27.61Z'
                opacity='.13'
            ></path>
            <path
                fill='url(#e)'
                d='M206.3 96.82a2.5 2.5 0 0 0-2.5 2.5v105.24c-15.33 6.51-114.12 48.61-122.35 56.95A2.5 2.5 0 0 0 85 265c5.72-5.8 73.08-35.61 118.79-55v123.3a2.5 2.5 0 0 0 5 0v-234a2.49 2.49 0 0 0-2.49-2.48Z'
                transform='translate(-7.54 -54.35)'
            ></path>
            <path
                fill='url(#f)'
                d='M453.15 297.06a2.51 2.51 0 0 0-3.4-1l-85.39 46.81v-96.93a2.5 2.5 0 1 0-5 0v96.81c-8.18-4.79-28.49-16.65-48.66-28.33-15.26-8.83-27.44-15.82-36.22-20.78-5.22-2.95-9.28-5.2-12.08-6.71-4.92-2.65-6.58-3.54-8-1.49-.32.46-1.19 2.12.94 3.8a2.55 2.55 0 0 0 1.7.53c9.33 4.63 64.62 36.7 102.3 58.77v99.73a2.5 2.5 0 0 0 5 0v-99.68l87.8-48.13a2.51 2.51 0 0 0 1.01-3.4Z'
                transform='translate(-7.54 -54.35)'
            ></path>
            <path
                fill='#fff'
                d='m30.03 136.28 166.62-92.64-.38 40.97-165.81 87.48-.43-35.81z'
                opacity='.24'
            ></path>
            <path
                fill='#fff'
                d='m201.27 229.64 150.55 78.34v85.94l-143.14-76.77-7.41-87.51z'
                opacity='.13'
            ></path>
            <path
                fill='none'
                stroke='#fff'
                stroke-miterlimit='10'
                stroke-width='5'
                d='M356.63 211.4s2.84 1.6 8.84 1.05'
            ></path>
            <path
                fill='none'
                stroke='#fff'
                stroke-dasharray='18.4 18.4'
                stroke-miterlimit='10'
                stroke-width='5'
                d='M382.89 206.83a111.11 111.11 0 0 0 17.66-11.5c30.41-23.68 76.37-28.53 88.81-16.68 68.67 65.56-66.81 181.18-132.73 184.78-34.85 1.9-56.24-61.55-64.75-93.37'
            ></path>
            <path
                fill='none'
                stroke='#fff'
                stroke-miterlimit='10'
                stroke-width='5'
                d='M289.61 261.16c-1.32-5.47-2-8.78-2-8.78l-4.53-7.73'
            ></path>
            <path
                fill='none'
                stroke='#fff'
                stroke-dasharray='17.64 17.64'
                stroke-miterlimit='10'
                stroke-width='5'
                d='m274.13 229.42-8.93-15.19c-22.45-38.15-63.94-22.64-63.94-22.64s-43.13 30.73-50.74 86.93-62.23 77.3-77.33 65.5-40.08-143.67 13.14-182.06c34.95-25.23 64.81-12.94 81.18-1.54'
            ></path>
            <path
                fill='none'
                stroke='#fff'
                stroke-miterlimit='10'
                stroke-width='5'
                d='M174.46 165.82a65.58 65.58 0 0 1 6.45 6.27'
            ></path>
        </svg>
    );
}

export default IdeaMapSVG;

import React from 'react';

function OriginalPaperSVG({ width = '525', height = '732' }) {
    return (
        <svg
            width={width}
            height={height}
            viewBox='0 0 525 732'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <g filter='url(#filter0_d_87_37)'>
                <rect
                    x='24'
                    y='5'
                    width='499'
                    height='706'
                    rx='33'
                    fill='#F4F4F4'
                />
            </g>
            <g filter='url(#filter1_d_87_37)'>
                <rect
                    x='136'
                    y='43'
                    width='275'
                    height='37'
                    rx='18.5'
                    fill='#3F3F3F'
                />
                <rect
                    x='136'
                    y='43'
                    width='275'
                    height='37'
                    rx='18.5'
                    fill='url(#paint0_linear_87_37)'
                    fill-opacity='0.2'
                />
            </g>
            <rect
                x='107'
                y='92'
                width='333'
                height='23'
                rx='11.5'
                fill='url(#paint1_linear_87_37)'
            />
            <rect
                x='62'
                y='173'
                width='74'
                height='23'
                rx='11.5'
                fill='url(#paint2_linear_87_37)'
            />
            <rect
                x='151'
                y='173'
                width='74'
                height='23'
                rx='11.5'
                fill='url(#paint3_linear_87_37)'
            />
            <rect
                x='240'
                y='173'
                width='147'
                height='23'
                rx='11.5'
                fill='url(#paint4_linear_87_37)'
            />
            <rect
                x='62'
                y='208'
                width='143'
                height='23'
                rx='11.5'
                fill='url(#paint5_linear_87_37)'
            />
            <rect
                x='62'
                y='409'
                width='194'
                height='23'
                rx='11.5'
                fill='url(#paint6_linear_87_37)'
            />
            <rect
                x='62'
                y='444'
                width='230'
                height='23'
                rx='11.5'
                fill='url(#paint7_linear_87_37)'
            />
            <rect
                x='62'
                y='479'
                width='89'
                height='23'
                rx='11.5'
                fill='url(#paint8_linear_87_37)'
            />
            <rect
                x='358'
                y='579'
                width='95'
                height='62'
                rx='7'
                fill='url(#paint9_linear_87_37)'
            />
            <rect
                x='271'
                y='409'
                width='36'
                height='23'
                rx='11.5'
                fill='url(#paint10_linear_87_37)'
            />
            <rect
                x='322'
                y='409'
                width='87'
                height='23'
                rx='11.5'
                fill='url(#paint11_linear_87_37)'
            />
            <rect
                x='307'
                y='444'
                width='74'
                height='23'
                rx='11.5'
                fill='url(#paint12_linear_87_37)'
            />
            <rect
                x='344'
                y='539'
                width='46'
                height='23'
                rx='11.5'
                fill='url(#paint13_linear_87_37)'
            />
            <rect
                x='344'
                y='658'
                width='123'
                height='23'
                rx='11.5'
                fill='url(#paint14_linear_87_37)'
            />
            <rect
                x='405'
                y='539'
                width='70'
                height='23'
                rx='11.5'
                fill='url(#paint15_linear_87_37)'
            />
            <rect
                x='62'
                y='256'
                width='94'
                height='23'
                rx='11.5'
                fill='url(#paint16_linear_87_37)'
            />
            <rect
                x='225'
                y='256'
                width='67'
                height='23'
                rx='11.5'
                fill='url(#paint17_linear_87_37)'
            />
            <rect
                x='307'
                y='256'
                width='67'
                height='23'
                rx='11.5'
                fill='url(#paint18_linear_87_37)'
            />
            <rect
                x='225'
                y='291'
                width='75'
                height='23'
                rx='11.5'
                fill='url(#paint19_linear_87_37)'
            />
            <rect
                x='225'
                y='326'
                width='119'
                height='23'
                rx='11.5'
                fill='url(#paint20_linear_87_37)'
            />
            <rect
                x='225'
                y='361'
                width='184'
                height='23'
                rx='11.5'
                fill='url(#paint21_linear_87_37)'
            />
            <rect
                x='198'
                y='256'
                width='13'
                height='23'
                rx='6.5'
                fill='url(#paint22_linear_87_37)'
            />
            <rect
                x='198'
                y='291'
                width='13'
                height='23'
                rx='6.5'
                fill='url(#paint23_linear_87_37)'
            />
            <rect
                x='198'
                y='326'
                width='13'
                height='23'
                rx='6.5'
                fill='url(#paint24_linear_87_37)'
            />
            <rect
                x='198'
                y='361'
                width='13'
                height='23'
                rx='6.5'
                fill='url(#paint25_linear_87_37)'
            />
            <rect
                x='62'
                y='291'
                width='74'
                height='23'
                rx='11.5'
                fill='url(#paint26_linear_87_37)'
            />
            <rect
                x='62'
                y='326'
                width='109'
                height='23'
                rx='11.5'
                fill='url(#paint27_linear_87_37)'
            />
            <rect
                x='62'
                y='361'
                width='89'
                height='23'
                rx='11.5'
                fill='url(#paint28_linear_87_37)'
            />
            <rect
                x='220'
                y='208'
                width='143'
                height='23'
                rx='11.5'
                fill='url(#paint29_linear_87_37)'
            />
            <defs>
                <filter
                    id='filter0_d_87_37'
                    x='0'
                    y='0'
                    width='525'
                    height='732'
                    filterUnits='userSpaceOnUse'
                    color-interpolation-filters='sRGB'
                >
                    <feFlood flood-opacity='0' result='BackgroundImageFix' />
                    <feColorMatrix
                        in='SourceAlpha'
                        type='matrix'
                        values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                        result='hardAlpha'
                    />
                    <feOffset dx='-11' dy='8' />
                    <feGaussianBlur stdDeviation='6.5' />
                    <feComposite in2='hardAlpha' operator='out' />
                    <feColorMatrix
                        type='matrix'
                        values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'
                    />
                    <feBlend
                        mode='normal'
                        in2='BackgroundImageFix'
                        result='effect1_dropShadow_87_37'
                    />
                    <feBlend
                        mode='normal'
                        in='SourceGraphic'
                        in2='effect1_dropShadow_87_37'
                        result='shape'
                    />
                </filter>
                <filter
                    id='filter1_d_87_37'
                    x='126'
                    y='43'
                    width='285'
                    height='44'
                    filterUnits='userSpaceOnUse'
                    color-interpolation-filters='sRGB'
                >
                    <feFlood flood-opacity='0' result='BackgroundImageFix' />
                    <feColorMatrix
                        in='SourceAlpha'
                        type='matrix'
                        values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                        result='hardAlpha'
                    />
                    <feMorphology
                        radius='1'
                        operator='erode'
                        in='SourceAlpha'
                        result='effect1_dropShadow_87_37'
                    />
                    <feOffset dx='-7' dy='4' />
                    <feGaussianBlur stdDeviation='2' />
                    <feComposite in2='hardAlpha' operator='out' />
                    <feColorMatrix
                        type='matrix'
                        values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'
                    />
                    <feBlend
                        mode='normal'
                        in2='BackgroundImageFix'
                        result='effect1_dropShadow_87_37'
                    />
                    <feBlend
                        mode='normal'
                        in='SourceGraphic'
                        in2='effect1_dropShadow_87_37'
                        result='shape'
                    />
                </filter>
                <linearGradient
                    id='paint0_linear_87_37'
                    x1='273.5'
                    y1='43'
                    x2='273.5'
                    y2='80'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stop-color='white' />
                    <stop offset='0.598958' stop-color='#3F3F3F' />
                </linearGradient>
                <linearGradient
                    id='paint1_linear_87_37'
                    x1='273.5'
                    y1='92'
                    x2='273.5'
                    y2='115'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stop-color='#CBCBCB' />
                    <stop offset='0.942708' stop-color='#8A8A8A' />
                </linearGradient>
                <linearGradient
                    id='paint2_linear_87_37'
                    x1='99'
                    y1='173'
                    x2='99'
                    y2='196'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stop-color='#CBCBCB' />
                    <stop offset='0.942708' stop-color='#8A8A8A' />
                </linearGradient>
                <linearGradient
                    id='paint3_linear_87_37'
                    x1='188'
                    y1='173'
                    x2='188'
                    y2='196'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stop-color='#CBCBCB' />
                    <stop offset='0.942708' stop-color='#8A8A8A' />
                </linearGradient>
                <linearGradient
                    id='paint4_linear_87_37'
                    x1='313.5'
                    y1='173'
                    x2='313.5'
                    y2='196'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stop-color='#CBCBCB' />
                    <stop offset='0.942708' stop-color='#8A8A8A' />
                </linearGradient>
                <linearGradient
                    id='paint5_linear_87_37'
                    x1='133.5'
                    y1='208'
                    x2='133.5'
                    y2='231'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stop-color='#CBCBCB' />
                    <stop offset='0.942708' stop-color='#8A8A8A' />
                </linearGradient>
                <linearGradient
                    id='paint6_linear_87_37'
                    x1='159'
                    y1='409'
                    x2='159'
                    y2='432'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stop-color='#CBCBCB' />
                    <stop offset='0.942708' stop-color='#8A8A8A' />
                </linearGradient>
                <linearGradient
                    id='paint7_linear_87_37'
                    x1='177'
                    y1='444'
                    x2='177'
                    y2='467'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stop-color='#CBCBCB' />
                    <stop offset='0.942708' stop-color='#8A8A8A' />
                </linearGradient>
                <linearGradient
                    id='paint8_linear_87_37'
                    x1='106.5'
                    y1='479'
                    x2='106.5'
                    y2='502'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stop-color='#CBCBCB' />
                    <stop offset='0.942708' stop-color='#8A8A8A' />
                </linearGradient>
                <linearGradient
                    id='paint9_linear_87_37'
                    x1='405.5'
                    y1='579'
                    x2='405.5'
                    y2='641'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stop-color='#EDFFBC' />
                    <stop offset='0.348958' stop-color='#EAFFAE' />
                    <stop offset='1' stop-color='#CEFF45' />
                </linearGradient>
                <linearGradient
                    id='paint10_linear_87_37'
                    x1='289'
                    y1='409'
                    x2='289'
                    y2='432'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stop-color='#CBCBCB' />
                    <stop offset='0.942708' stop-color='#8A8A8A' />
                </linearGradient>
                <linearGradient
                    id='paint11_linear_87_37'
                    x1='365.5'
                    y1='409'
                    x2='365.5'
                    y2='432'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stop-color='#CBCBCB' />
                    <stop offset='0.942708' stop-color='#8A8A8A' />
                </linearGradient>
                <linearGradient
                    id='paint12_linear_87_37'
                    x1='344'
                    y1='444'
                    x2='344'
                    y2='467'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stop-color='#CBCBCB' />
                    <stop offset='0.942708' stop-color='#8A8A8A' />
                </linearGradient>
                <linearGradient
                    id='paint13_linear_87_37'
                    x1='367'
                    y1='539'
                    x2='367'
                    y2='562'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stop-color='#CBCBCB' />
                    <stop offset='0.942708' stop-color='#8A8A8A' />
                </linearGradient>
                <linearGradient
                    id='paint14_linear_87_37'
                    x1='405.5'
                    y1='658'
                    x2='405.5'
                    y2='681'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stop-color='#CBCBCB' />
                    <stop offset='0.942708' stop-color='#8A8A8A' />
                </linearGradient>
                <linearGradient
                    id='paint15_linear_87_37'
                    x1='440'
                    y1='539'
                    x2='440'
                    y2='562'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stop-color='#CBCBCB' />
                    <stop offset='0.942708' stop-color='#8A8A8A' />
                </linearGradient>
                <linearGradient
                    id='paint16_linear_87_37'
                    x1='109'
                    y1='256'
                    x2='109'
                    y2='279'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stop-color='#CBCBCB' />
                    <stop offset='0.942708' stop-color='#8A8A8A' />
                </linearGradient>
                <linearGradient
                    id='paint17_linear_87_37'
                    x1='258.5'
                    y1='256'
                    x2='258.5'
                    y2='279'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stop-color='#CBCBCB' />
                    <stop offset='0.942708' stop-color='#8A8A8A' />
                </linearGradient>
                <linearGradient
                    id='paint18_linear_87_37'
                    x1='340.5'
                    y1='256'
                    x2='340.5'
                    y2='279'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stop-color='#CBCBCB' />
                    <stop offset='0.942708' stop-color='#8A8A8A' />
                </linearGradient>
                <linearGradient
                    id='paint19_linear_87_37'
                    x1='262.5'
                    y1='291'
                    x2='262.5'
                    y2='314'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stop-color='#CBCBCB' />
                    <stop offset='0.942708' stop-color='#8A8A8A' />
                </linearGradient>
                <linearGradient
                    id='paint20_linear_87_37'
                    x1='284.5'
                    y1='326'
                    x2='284.5'
                    y2='349'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stop-color='#CBCBCB' />
                    <stop offset='0.942708' stop-color='#8A8A8A' />
                </linearGradient>
                <linearGradient
                    id='paint21_linear_87_37'
                    x1='317'
                    y1='361'
                    x2='317'
                    y2='384'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stop-color='#CBCBCB' />
                    <stop offset='0.942708' stop-color='#8A8A8A' />
                </linearGradient>
                <linearGradient
                    id='paint22_linear_87_37'
                    x1='204.5'
                    y1='256'
                    x2='204.5'
                    y2='279'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stop-color='#CBCBCB' />
                    <stop offset='0.942708' stop-color='#8A8A8A' />
                </linearGradient>
                <linearGradient
                    id='paint23_linear_87_37'
                    x1='204.5'
                    y1='291'
                    x2='204.5'
                    y2='314'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stop-color='#CBCBCB' />
                    <stop offset='0.942708' stop-color='#8A8A8A' />
                </linearGradient>
                <linearGradient
                    id='paint24_linear_87_37'
                    x1='204.5'
                    y1='326'
                    x2='204.5'
                    y2='349'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stop-color='#CBCBCB' />
                    <stop offset='0.942708' stop-color='#8A8A8A' />
                </linearGradient>
                <linearGradient
                    id='paint25_linear_87_37'
                    x1='204.5'
                    y1='361'
                    x2='204.5'
                    y2='384'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stop-color='#CBCBCB' />
                    <stop offset='0.942708' stop-color='#8A8A8A' />
                </linearGradient>
                <linearGradient
                    id='paint26_linear_87_37'
                    x1='99'
                    y1='291'
                    x2='99'
                    y2='314'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stop-color='#CBCBCB' />
                    <stop offset='0.942708' stop-color='#8A8A8A' />
                </linearGradient>
                <linearGradient
                    id='paint27_linear_87_37'
                    x1='116.5'
                    y1='326'
                    x2='116.5'
                    y2='349'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stop-color='#CBCBCB' />
                    <stop offset='0.942708' stop-color='#8A8A8A' />
                </linearGradient>
                <linearGradient
                    id='paint28_linear_87_37'
                    x1='106.5'
                    y1='361'
                    x2='106.5'
                    y2='384'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stop-color='#CBCBCB' />
                    <stop offset='0.942708' stop-color='#8A8A8A' />
                </linearGradient>
                <linearGradient
                    id='paint29_linear_87_37'
                    x1='291.5'
                    y1='208'
                    x2='291.5'
                    y2='231'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stop-color='#CBCBCB' />
                    <stop offset='0.942708' stop-color='#8A8A8A' />
                </linearGradient>
            </defs>
        </svg>
    );
}

export default OriginalPaperSVG;

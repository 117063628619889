import React from 'react';

function index() {
    const routes = [
        { path: '/', lastModified: '2023-08-11', changeFrequency: 'weekly' },
        {
            path: '/home',
            lastModified: '2023-08-11',
            changeFrequency: 'weekly',
        },
        {
            path: '/events',
            lastModified: '2023-08-11',
            changeFrequency: 'weekly',
        },
        {
            path: '/asec',
            lastModified: '2023-08-11',
            changeFrequency: 'weekly',
        },
        {
            path: '/asec/panduan',
            lastModified: '2023-08-11',
            changeFrequency: 'weekly',
        },
        {
            path: '/asic',
            lastModified: '2023-08-11',
            changeFrequency: 'weekly',
        },
        {
            path: '/asic/sma',
            lastModified: '2023-08-11',
            changeFrequency: 'weekly',
        },
        {
            path: '/asic/sma/panduan',
            lastModified: '2023-08-11',
            changeFrequency: 'weekly',
        },
        {
            path: '/asic/mhs',
            lastModified: '2023-08-11',
            changeFrequency: 'weekly',
        },
        {
            path: '/asic/mhs/panduan',
            lastModified: '2023-08-11',
            changeFrequency: 'weekly',
        },
        { path: '/wod', lastModified: '2023-08-11', changeFrequency: 'weekly' },
        { path: '/ave', lastModified: '2023-08-11', changeFrequency: 'weekly' },
        {
            path: '/announcement',
            lastModified: '2023-08-11',
            changeFrequency: 'weekly',
        },
        {
            path: '/information',
            lastModified: '2023-08-11',
            changeFrequency: 'weekly',
        },
        {
            path: '/privacy-policy',
            lastModified: '2023-08-11',
            changeFrequency: 'weekly',
        },
        { path: '/tnc', lastModified: '2023-08-11', changeFrequency: 'weekly' },
    ];

    const sitemap = routes.map((route) => ({
        url: route.path,
        lastmod: route.lastModified,
        changefreq: route.changeFrequency,
    }));

    return (
        <div>
            <pre>{JSON.stringify(sitemap, null, 2)}</pre>
        </div>
    );
}

export default index;

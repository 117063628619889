import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import ContactCard from "../../component/ContactCard";
import AOS from "aos";
import AsecSVG from "../../component/SVGs/AsecSVG";
import { Timeline, TimelineBlock } from "../../component/Timeline";
import Image from "../../component/Image";
import Rules from "../../component/Rules";

const rules = [
  "Peserta merupakan mahasiswa/i aktif program pendidikan Diploma (D3/D4) atau Sarjana (S1) dari seluruh Perguruan Tinggi di Indonesia yang dibuktikan dengan Kartu Tanda Mahasiswa (KTM).",
  "Perlombaan dapat diikuti oleh individu atau tim yang terdiri atas dua orang dengan satu peserta sebagai ketua.",
  "Peserta diperbolehkan berasal dari jurusan yang berbeda namun tetap dari Perguruan Tinggi yang sama.",
  "Peserta yang sudah terdaftar tidak dapat digantikan oleh orang lain dengan alasan apapun.",
  "Peserta diperbolehkan mengikuti lebih dari satu cabang perlombaan pada ARSEN 2024 dengan ketentuan hanya boleh menjadi ketua tim pada salah satu cabang perlombaan yang diikuti.",
  "Peserta tidak diperbolehkan mendaftar untuk dua atau lebih tim yang berbeda dalam satu cabang perlombaan yang sama.",
  "Peserta hanya dapat memilih satu cabang perlombaan ketika lebih dari satu cabang dinyatakan lolos ke Babak Final.",
  "Peserta wajib mengunggah twibbon ARSEN 2024 di instagram pribadi dengan akun publik selama rangkaian kegiatan berlangsung dan tag ke @himasta_unair, @arsenunair, serta tag 3 akun teman lainnya menggunakan caption yang telah disediakan. Twibbon dan caption dapat diunduh pada link berikut : https://bit.ly/TWIBBONASEC2024",
  "Peserta yang dianggap sah adalah peserta yang telah mendaftar secara sah oleh panitia dan mengikuti prosedur hingga selesai.",
  "Peserta wajib mematuhi segala peraturan yang telah ditetapkan oleh panitia dan jika peserta melanggar peraturan ASEC 2024 maka akan diberikan sanksi sesuai peraturan yang berlaku.",
];

function Asec() {
  useEffect(() => {
    AOS.init();
  }, []);
  const handleLinkClick = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div className="flex flex-col gap-3 text-center md:text-left mx-8 px-0 md:px-8 my-16">
      <div className="flex items-center flex-col md:flex-row gap-3 py-8">
        <div className="flex flex-col gap-3">
          <div data-aos="fade-up">
            <div className="font-bold text-sm tracking-widest text-arsen-secondary-500 uppercase">
              CABANG KEGIATAN
            </div>
            <div className="text-5xl md:text-6xl font-bold">
              <span className="text-arsen-secondary">A</span>irlangga{" "}
              <span className="text-arsen-secondary">S</span>tatistics{" "}
              <span className="text-arsen-secondary">E</span>ssay{" "}
              <span className="text-arsen-secondary">C</span>ompetition
            </div>
          </div>
          <div
            data-aos="fade-up"
            data-aos-delay="100"
            className="bg-arsen-secondary-100 text-arsen-secondary rounded-full md:w-fit px-4 py-1 font-medium text-sm"
          >
            Tingkat Mahasiswa se-Indonesia
          </div>
          <div data-aos="fade-up" data-aos-delay="150">
            <div className="font-bold text-sm tracking-widest text-arsen-secondary-500 uppercase">
              TEMA KOMPETISI
            </div>
            <div className="text-sm md:text-lg leading-6 text-gray-700">
              Realizing the Role of Frontliners: Future Innovation Strategies
              Through the Application of Statistics to Overcome Inequality
            </div>
          </div>
          <div
            data-aos="fade-up"
            data-aos-delay="200"
            className="flex flex-col md:flex-row gap-2.5 text-md md:text-lg text-center md:text-left text-white mb-8"
          >
            <Link
              to="https://bit.ly/FormPendaftaranASECBatch2"
              target="_blank"
              className="bg-arsen-secondary md:w-fit text-center px-8 py-3 rounded-lg transition duration-500 hover:bg-black hover:shadow-xl"
            >
              Daftar Sekarang!
            </Link>
            <Link
              to="/asec/panduan/"
              onClick={handleLinkClick}
              className="text-center text-arsen-secondary border border-arsen-secondary md:w-fit px-8 py-3 rounded-lg transition duration-500 hover:bg-black hover:text-white hover:shadow-xl"
            >
              Panduan Kegiatan
            </Link>
            <a
              href="#timeline"
              className="text-center text-arsen-secondary border border-arsen-secondary md:w-fit px-8 py-3 rounded-lg transition duration-500 hover:bg-black hover:text-white hover:shadow-xl"
            >
              Timeline Kompetisi
            </a>
          </div>
        </div>
        <div
          data-aos="fade-up"
          className="md:ml-4 text-white text-center justify-center"
        >
          <AsecSVG width="400" height="auto" />
        </div>
      </div>
      <div
        data-aos="zoom-in"
        className="flex sm:hidden flex-col items-center justify-center gap-2"
      >
        <div className="font-bold text-sm tracking-widest text-gray-600 uppercase">
          POSTER KEGIATAN
        </div>
        <div className="w-full md:w-1/4">
          <Image
            src="/assets/img/poster/ASEC BATCH 2 (3) (Medium).png"
            alt="ASEC Poster"
          />
        </div>
      </div>
      <div className="flex flex-col md:flex-row gap-4 md:gap-12">
        <div data-aos="zoom-in" className="flex flex-col gap-3">
          <div className="font-bold text-sm tracking-widest text-arsen-secondary-500 uppercase">
            TENTANG KOMPETISI
          </div>
          <p className="text-justify text-gray-700 text-md">
            <span className="font-bold">
              Airlangga Statistics Essay Competition
            </span>
            <span className="font-normal">
              {" "}
              (ASEC) pada kegiatan ARSEN 2024 merupakan ajang bergengsi yang
              ditunjukkan pada mahasiswa/mahasiswi Perguruan Tinggi nasional
              yang memiliki minat terhadap pembuatan karya tulis essay. Dengan
              mengangkat Tema “Realizing the Role of Frontliners: Future
              innovation strategies through the application of statistics to
              overcome inequality” yang dibawakan dengan pembuatan karya essay
              yang dapat menggambarkan bagaimana strategi inovasi masa depan
              yang sesuai melalui penerapan statistika untuk mengatasi
              ketimpangan.
            </span>
          </p>
        </div>
        <div data-aos="zoom-in" className="flex flex-col gap-3">
          <div className="font-bold text-sm tracking-widest text-arsen-secondary-500 uppercase">
            BIAYA PENDAFTARAN ASEC
          </div>
          <div className="flex flex-col md:flex-row gap-3">
            <div className="hover:shadow-xl hover:bg-black group transition duration-500 p-4 px-12 bg-arsen-secondary-700 rounded-2xl flex-col justify-start items-center gap-2.5 inline-flex">
              <div className="pl-4 pr-1 p-1 bg-gray-200 rounded-2xl justify-center items-center gap-2.5 inline-flex">
                <div className="text-center text-arsen-secondary-500 text-xs font-normal leading-8">
                  First Batch
                </div>
                <div className="px-2 py-2 bg-arsen-secondary-700 group-hover:bg-black transition duration-500 rounded-xl justify-center items-center gap-2.5 flex">
                  <div className="text-cente text-white text-xs font-normal">
                    Soon!
                  </div>
                </div>
              </div>
              <div className="self-stretch flex-col justify-center items-center flex">
                <div className="text-center text-neutral-200 text-sm font-normal line-through leading-normal">
                  Rp90.000
                </div>
                <div className="justify-center items-start inline-flex">
                  <div className="text-center text-white text-sm font-medium leading-normal">
                    Rp
                  </div>
                  <div className="text-center text-white text-4xl font-bold">
                    60.000
                  </div>
                  <div className="w-[39px] self-stretch justify-center items-end gap-2.5 flex">
                    <div className="text-center text-neutral-200 text-xs font-medium leading-[21px]">
                      /team
                    </div>
                  </div>
                </div>
              </div>
              <div className="px-4 rounded-full border border-gray-200 justify-center items-center gap-1.5 inline-flex">
                <div className="text-center text-white text-xs font-bold leading-[21px]">
                  %
                </div>
                <div className="text-center text-white text-xs font-normal leading-[21px]">
                  -Rp30.000 OFF!
                </div>
              </div>
              <div className="self-stretch text-center text-neutral-200 text-sm font-medium leading-normal">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4 inline-block mr-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M5 13l4 4L19 7"
                  />
                </svg>
                2 Members / Team
              </div>
              <div className="self-stretch text-center text-neutral-200 text-sm font-medium leading-normal">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4 inline-block mr-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M5 13l4 4L19 7"
                  />
                </svg>
                National E-Certificate
              </div>
            </div>
            <div className="hover:shadow-xl hover:bg-black group transition duration-500 p-4 px-12 bg-arsen-secondary-800 rounded-2xl flex-col justify-start items-center gap-2.5 inline-flex">
              <div className="pl-4 pr-1 p-1 bg-gray-200 rounded-2xl justify-center items-center gap-2.5 flex">
                <div className="text-center text-arsen-secondary-500 text-xs font-normal leading-8">
                  Last Batch
                </div>
                <div className="px-2 py-2 bg-arsen-secondary-800 group-hover:bg-black transition duration-500 rounded-xl justify-center items-center gap-2.5 flex">
                  <div className="text-cente text-white text-xs font-normal">
                    Soon!
                  </div>
                </div>
              </div>
              <div className="self-stretch flex-col justify-center items-center flex">
                <div className="justify-center items-start inline-flex">
                  <div className="text-center text-white text-sm font-medium leading-normal">
                    Rp
                  </div>
                  <div className="text-center text-white text-4xl font-bold">
                    90.000
                  </div>
                  <div className="w-[39px] self-stretch justify-center items-end gap-2.5 flex">
                    <div className="text-center text-neutral-200 text-xs font-medium leading-[21px]">
                      /team
                    </div>
                  </div>
                </div>
              </div>
              <div className="px-4 rounded-full border border-gray-200 justify-center items-center gap-1.5 inline-flex">
                <div className="text-center text-white text-xs font-normal leading-[21px]">
                  Normal Price
                </div>
              </div>
              <div className="self-stretch text-center text-neutral-200 text-sm font-medium leading-normal">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4 inline-block mr-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M5 13l4 4L19 7"
                  />
                </svg>
                2 Members / Team
              </div>
              <div className="self-stretch text-center text-neutral-200 text-sm font-medium leading-normal">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4 inline-block mr-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M5 13l4 4L19 7"
                  />
                </svg>
                National E-Certificate
              </div>
            </div>
          </div>
        </div>
      </div>
      <div data-aos="zoom-in" className="flex flex-col gap-3 mb-4">
        <div className="font-bold text-sm tracking-widest text-arsen-secondary-500 uppercase">
          Competition Prize
        </div>
        <div className="grid gap-4 md:grid-cols-2">
          <div className="flex flex-col md:flex-row p-4 border rounded-lg bg-white shadow-lg hover:shadow-none transition duration-200 ease-in gap-2.5">
            <div className="flex flex-col">
              <div className="text-lg font-bold">1st, 2nd, and 3rd Winner</div>
              <div className="ordinal text-md">
                Coaching Cash, Faculty Tour, & National E-Certificate
              </div>
            </div>
          </div>
          <div className="flex flex-col md:flex-row p-4 border rounded-lg bg-white shadow-lg hover:shadow-none transition duration-200 ease-in gap-2.5">
            <div className="flex flex-col">
              <div className="text-lg font-bold">Best Speaker</div>
              <div className="ordinal text-md">
                Coaching Cash & National E-Certificate
              </div>
            </div>
          </div>
        </div>
      </div>
      <div data-aos="zoom-in" className="flex flex-col gap-3 mb-4">
        <div className="font-bold text-sm tracking-widest text-arsen-secondary-500 uppercase">
          Syarat dan Ketentuan
        </div>
        <Rules rules={rules} />
      </div>
      <div data-aos="zoom-in" className="flex flex-col gap-3" id="timeline">
        <div className="font-bold text-sm tracking-widest text-arsen-secondary-500 uppercase">
          TIMELINE KOMPETISI ASEC
        </div>
        <Timeline month={"August"}>
          <TimelineBlock date="9 August 2024">
            Open Registration Batch 1
          </TimelineBlock>
          <TimelineBlock date="17 August 2024">
            Open for Submission
          </TimelineBlock>
          <TimelineBlock date="30 August 2024">
            Close Registration Batch 1
          </TimelineBlock>
          <TimelineBlock date="31 August 2024">
            Technical Meeting for Preliminary Round
          </TimelineBlock>
        </Timeline>
        <Timeline month="September">
          <TimelineBlock date="1 September 2024">
            Open Registration Batch 2
          </TimelineBlock>
          <TimelineBlock date="16 September 2024">
            Close Registration Batch 2
          </TimelineBlock>
          <TimelineBlock date="20 September 2024">
            Close for Submission
          </TimelineBlock>
          <TimelineBlock date="21 September 2024">
            Start of Preliminary Round
          </TimelineBlock>
        </Timeline>
        <Timeline month="October">
          <TimelineBlock date="5 October 2024">
            End of Preliminary Round
          </TimelineBlock>
          <TimelineBlock date="14 October 2024">
            Finalist Announcement
          </TimelineBlock>
          <TimelineBlock date="14 October 2024">
            Open Re-registration for Finalist
          </TimelineBlock>
          <TimelineBlock date="16 October 2024">
            Close Re-registration for Finalist
          </TimelineBlock>
          <TimelineBlock date="26 October 2024">
            Technical Meeting for Finalist Round
          </TimelineBlock>
        </Timeline>
        <Timeline month="November">
          <TimelineBlock date="3 November 2024">
            Finalist Round & Closing Ceremony
          </TimelineBlock>
        </Timeline>
      </div>
      <div data-aos="fade-right" className="flex flex-col gap-4 my-16">
        <div className="font-bold text-sm tracking-widest text-arsen-secondary-500 uppercase uppercase">
          Informasi Lainnya
        </div>
        <div className="flex-col justify-start md:items-start flex">
          <div className="text-center md:text-left text-black text-[32px] font-bold">
            Pengumpulan Karya ASEC 2024
          </div>
        </div>
        <div className="flex flex-col md:flex-row gap-2.5 text-sm text-center md:text-left text-white mb-8">
          <Link
            to="https://bit.ly/FormPengumpulanKaryaASEC2024"
            target="_blank"
            className="bg-arsen-secondary md:w-fit text-center px-8 py-3 rounded-lg transition duration-500 hover:bg-black hover:shadow-xl"
          >
            Pengumpulan Karya
          </Link>
          <Link
            to="/asec/panduan/"
            onClick={handleLinkClick}
            className="text-center text-arsen-secondary border border-arsen-secondary md:w-fit px-8 py-3 rounded-lg transition duration-500 hover:bg-black hover:text-white hover:shadow-xl"
          >
            Panduan Kegiatan
          </Link>
          <a
            href="#timeline"
            className="text-arsen-secondary border border-arsen-secondary md:w-fit px-8 py-3 rounded-lg transition duration-500 hover:bg-black hover:text-white hover:shadow-xl"
          >
            Timeline Kompetisi
          </a>
        </div>
      </div>
      <div data-aos="fade-right">
        <ContactCard event="ASEC" />
      </div>
    </div>
  );
}

export default Asec;

import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import AOS from "aos";
import { handleLinkClick } from "../../scripts/navigationHandling";
import AVESVG from "../../component/SVGs/AVESVG";
import { toast } from "react-toastify";

function AVE() {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div className="flex flex-col gap-3 text-center md:text-left mx-8 px-0 md:px-8 my-16">
      <div className="flex items-center flex-col md:flex-row gap-3 py-8">
        <div className="flex flex-col gap-3">
          <div data-aos="fade-up">
            <div className="font-bold text-sm tracking-widest text-arsen-primary uppercase">
              Airlangga Statistics Events
            </div>
            <div className="text-5xl md:text-6xl font-bold">
              <span className="text-arsen-primary">A</span>irlangga{" "}
              <span className="text-arsen-primary">V</span>irtual{" "}
              <span className="text-arsen-primary">E</span>xhibition{" "}
            </div>
          </div>
          <div
            data-aos="fade-up"
            className="bg-gray-200 text-arsen-primary rounded-full md:w-fit px-4 py-1 font-medium text-sm"
          >
            Pameran Karya Infografis
          </div>
          <div data-aos="fade-up">
            <div className="font-bold text-sm tracking-widest text-arsen-primary uppercase">
              TEMA KEGIATAN
            </div>
            <div className="text-sm md:text-lg leading-6 text-gray-700">
              Envisioning Justice: The Role of Data in Driving Action for
              Equality
            </div>
          </div>
          <div
            data-aos="fade-up"
            className="flex flex-col md:flex-row gap-2.5 text-md md:text-lg text-center md:text-left text-white mb-8"
          >
            <Link
              onClick={() => {
                toast.info("Coming soon!");
              }}
              to="#"
              className="bg-arsen-primary md:w-fit text-center px-8 py-3 rounded-lg transition duration-500 hover:bg-black hover:shadow-xl"
            >
              Lihat AVE
            </Link>
            <Link
              onClick={handleLinkClick}
              to="/ave/links"
              className="border border-arsen-primary text-arsen-primary hover:text-white md:w-fit text-center px-8 py-3 rounded-lg transition duration-150 hover:bg-arsen-primary hover:shadow-xl"
            >
              Lihat AVE 2023
            </Link>
          </div>
        </div>
        <div
          data-aos="fade-up"
          className="md:ml-4 text-white text-center justify-center"
        >
          <AVESVG width="400" height="auto" />
        </div>
      </div>
    </div>
  );
}

export default AVE;

import React from "react";
import { InstagramSVG, LinkedInSVG, TikTokSVG } from "../SVGs/SocialSVGs";
import { Link } from "react-router-dom";
import { Arsen2024LogoOnlySVG } from "../SVGs/ArsenLogo2024SVG";

function Footer() {
  return (
    <div className="flex-col justify-start items-start inline-flex">
      <div className="self-stretch px-5 py-4 bg-arsen-primary-900 flex-col justify-start items-start flex">
        <div className="flex-col justify-start items-start gap-2.5 flex md:flex-row">
          <div className="flex-1 px-4 py-2 flex-col justify-center items-start gap-2.5 flex">
            <Arsen2024LogoOnlySVG sideLength="64" />
            <div className="self-stretch text-white text-sm font-normal">
              Situs ini di bawah naungan Airlangga Statistics Events.
            </div>
            <div className="justify-start items-center gap-2.5 inline-flex">
              <div className="flex gap-2">
                <Link
                  to="https://www.instagram.com/arsenunair"
                  target="_blank"
                  className="text-white"
                >
                  <InstagramSVG width="25" height="auto" />
                </Link>
              </div>
              <div className="flex gap-2">
                <Link
                  to="https://www.linkedin.com/company/linkedinairlanggastatisticsevent/"
                  target="_blank"
                  className="text-white"
                >
                  <LinkedInSVG width="25" height="auto" />
                </Link>
              </div>
              <div className="flex gap-2">
                <Link
                  to="https://www.tiktok.com/@arsenunair"
                  target="_blank"
                  className="text-white"
                >
                  <TikTokSVG width="25" height="auto" />
                </Link>
              </div>
            </div>
          </div>
          <div className="flex-1 px-4 py-2 flex-col justify-center items-start gap-2.5 flex">
            <div className="text-white text-lg font-semibold">Kontak Umum</div>
            <div className="self-stretch pl-4 border-l border-white flex-col justify-start items-center gap-1 flex">
              <div className="self-stretch text-white text-md font-bold">
                Alamat
              </div>
              <div className="self-stretch text-white text-sm font-normal">
                Sekretariat Himpunan Mahasiswa Statistika, Fakultas Sains dan
                Teknologi, Universitas Airlangga - Kampus C, Mulyorejo,
                Surabaya, Jawa Timur 60115
              </div>
            </div>
            <div className="self-stretch pl-4 border-l border-white flex-col justify-start items-center gap-1 flex">
              <div className="self-stretch text-white text-sm font-bold">
                Nara Hubung
              </div>
              <div className="self-stretch text-white text-sm font-normal">
                Nomor Telepon:{" "}
                <Link
                  className="hover:text-gray-400 transition duration-500"
                  to="https://wa.me/628233420745"
                >
                  +62 823-3420-745 (Ais)
                </Link>
              </div>
              <div className="self-stretch text-white text-sm font-normal">
                Email:{" "}
                <Link
                  className="hover:text-gray-400 transition duration-500"
                  to="mailto:arsenunair@gmail.com"
                >
                  arsenunair@gmail.com
                </Link>
              </div>
            </div>
          </div>
          <div className="flex-1 px-4 py-2 flex-col justify-center items-start gap-2.5 flex">
            <div className="text-white text-lg font-semibold">Link</div>
            <Link
              to="https://wa.me/6285859963963"
              className="self-stretch pl-4 border-l border-white flex-col justify-start items-center gap-1 flex text-white hover:text-gray-400 transition duration-500"
            >
              <div className="self-stretch text-sm font-normal">
                Daftar Mitra Sponsor
              </div>
            </Link>
            <Link
              target="_blank"
              rel="noopener noreferrer"
              to="https://himastaunair.com"
              className="self-stretch pl-4 border-l border-white flex-col justify-start items-center gap-1 flex text-white hover:text-gray-400 transition duration-500"
            >
              <div className="self-stretch text-sm font-normal">
                Himpunan Mahasiswa Statistika Unair
              </div>
            </Link>
            <Link
              target="_blank"
              rel="noopener noreferrer"
              to="https://stat.fst.unair.ac.id"
              className="self-stretch pl-4 border-l border-white flex-col justify-start items-center gap-1 flex text-white hover:text-gray-400 transition duration-500"
            >
              <div className="self-stretch text-sm font-normal">
                Program Studi Statistika, Universitas Airlangga
              </div>
            </Link>
            <Link
              target="_blank"
              rel="noopener noreferrer"
              to="https://unair.ac.id"
              className="self-stretch pl-4 border-l border-white flex-col justify-start items-center gap-1 flex text-white hover:text-gray-400 transition duration-500"
            >
              <div className="self-stretch text-sm font-normal">
                Universitas Airlangga
              </div>
            </Link>
          </div>
        </div>
        <div className="self-stretch px-4 py-2 flex-col justify-center items-start gap-2.5 flex">
          <div className="text-white text-lg font-semibold">Sponsor Kami</div>
          <div className="text-white self-stretch justify-start items-start gap-3 inline-flex text-sm">
            Terbuka untuk Sponsor, silahkan hubungi kami.
          </div>
        </div>
      </div>
      <div className="self-stretch flex-col justify-start items-start flex">
        <div className="self-stretch px-5 py-4 bg-arsen-primary-500 flex-col justify-start items-start flex">
          {/*<div className='flex md:flex-row-reverse px-4 py-2 justify-start items-center gap-2.5'>*/}
          {/*  <div className='text-white text-sm font-normal'>*/}
          {/*    Terms*/}
          {/*  </div>*/}
          {/*  <div className='justify-center items-center gap-2.5 flex'>*/}
          {/*    <div className='w-0.5 h-0.5 bg-zinc-300 rounded-full'></div>*/}
          {/*  </div>*/}
          {/*  <div className='text-white text-sm font-normal'>*/}
          {/*    Privacy Policy*/}
          {/*  </div>*/}
          {/*</div>*/}
          <div className="px-4 py-2 text-white text-sm font-normal">
            &copy; Airlangga Statistics Events (ARSEN). All rights reserved.
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;

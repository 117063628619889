import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import AOS from "aos";

// import CheckRoundedSVG from "../../component/SVGs/CheckRoundedSVG";

function EventsPage() {
  const handleLinkClick = () => {
    window.scroll(0, 0);
  };
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div className="flex flex-col mb-16">
      <div className="flex flex-col md:flex-row mx-8 px-0 md:px-8 mt-16">
        <div data-aos="fade-up" className="flex flex-col">
          <div className="text-center md:text-left text-sm mb-2 font-bold text-arsen-primary tracking-widest">
            PILIHAN CABANG KEGIATAN
          </div>
          <div className="text-5xl font-bold text-center md:text-left mb-4">
            Airlangga Statistics Events
          </div>
        </div>
      </div>
      <div
        data-aos="fade-up"
        className="flex flex-col mx-8 my-4 px-0 md:px-8 gap-4 text-left"
      >
        <Link
          onClick={handleLinkClick}
          to="/opening"
          className="group p-4 rounded-2xl text-black bg-white border shadow-md hover:shadow-none hover:bg-arsen-quaternary hover:text-white transition duration-500 w-full"
        >
          <div className="text-4xl mb-2 font-bold">Opening</div>
          <div className="text-sm font-medium mb-2 text-arsen-quaternary group-hover:text-white transition duration-500">
            <i>Ceremony and Talkshow</i>
          </div>
          <div className="mb-2 px-2 py-1 text-sm text-arsen-quaternary bg-arsen-quaternary-100 group-hover:bg-arsen-quaternary-400 group-hover:text-white rounded-lg transition duration-500">
            The Beginning : Unleash The True You, Find The Path to Extraordinary
          </div>
          <div className="text-sm text-left text-arsen-primary group-hover:text-white transition duration-500">
            {/* <ul className="space-y-4">
              <li className="flex items-center">
                <CheckRoundedSVG />
                <p className="ml-2">Terbuka untuk umum</p>
              </li>
              <li className="flex items-center">
                <CheckRoundedSVG />
                <p className="ml-2">Gratis</p>
              </li>
            </ul> */}
          </div>
        </Link>
        <Link
          onClick={handleLinkClick}
          to="/asec"
          className="group p-4 rounded-2xl text-black bg-white border shadow-md hover:shadow-none hover:bg-arsen-secondary-800 hover:text-white transition duration-500 w-full"
        >
          <div className="text-4xl mb-2 font-bold">ASEC</div>
          <div className="text-sm font-medium mb-2 text-arsen-secondary group-hover:text-white transition duration-500">
            <i>
              <b>A</b>irlangga <b>S</b>tatistics <b>E</b>ssay <b>C</b>ompetition
            </i>
          </div>
          <div className="mb-2 px-2 py-1 text-sm text-arsen-secondary bg-arsen-secondary-100 group-hover:bg-arsen-secondary-400 group-hover:text-white rounded-lg transition duration-500">
            Realizing the Role of Frontliners: Future Innovation Strategies
            Through the Application of Statistics to Overcome Inequality
          </div>
          <div className="text-sm text-left text-arsen-primary group-hover:text-white transition duration-500">
            {/* <ul className="space-y-4">
              <li className="flex items-center">
                <CheckRoundedSVG />
                <p className="ml-2">Tingkat mahasiswa se-Indonesia</p>
              </li>
              <li className="flex items-center">
                <CheckRoundedSVG />
                <p className="ml-2">2-3 Peserta/Tim</p>
              </li>
              <li className="flex items-center">
                <CheckRoundedSVG />
                <p className="ml-2">Total hadiah jutaan rupiah</p>
              </li>
              <li className="flex items-center">
                <CheckRoundedSVG />
                <p className="ml-2">Kesempatan Fakultas Tour bagi Finalis!</p>
              </li>
            </ul> */}
          </div>
        </Link>
        <Link
          onClick={handleLinkClick}
          to="/asic"
          className="group p-4 rounded-2xl text-black bg-white border shadow-md hover:shadow-none hover:bg-arsen-primary hover:text-white transition duration-500 w-full"
        >
          <div className="text-4xl mb-2 font-bold">ASIC</div>
          <div className="text-sm font-medium mb-2 text-arsen-primary group-hover:text-white transition duration-500">
            <i>
              <b>A</b>irlangga <b>S</b>tatistics <b>I</b>
              nfographic <b>C</b>ompetition
            </i>
          </div>
          <div className="mb-2 px-2 py-1 text-sm text-arsen-primary bg-arsen-primary-100 group-hover:bg-arsen-primary-400 group-hover:text-white rounded-lg transition duration-500">
            Envisioning Justice: The Role of Data in Driving Action for Equality
          </div>
          <div className="text-sm  text-left text-arsen-primary group-hover:text-white transition duration-500">
            {/* <ul className="space-y-4">
              <li className="flex items-center">
                <CheckRoundedSVG />
                <p className="ml-2">
                  Tingkat mahasiswa dan SMA/Sederajat se-Indonesia (seleksi
                  terpisah)
                </p>
              </li>
              <li className="flex items-center">
                <CheckRoundedSVG />
                <p className="ml-2">2 Peserta/Tim</p>
              </li>
              <li className="flex items-center">
                <CheckRoundedSVG />
                <p className="ml-2">Total hadiah jutaan rupiah</p>
              </li>
              <li className="flex items-center">
                <CheckRoundedSVG />
                <p className="ml-2">Kesempatan Fakultas Tour bagi Finalis!</p>
              </li>
            </ul> */}
          </div>
        </Link>
        <Link
          onClick={handleLinkClick}
          to="/aso"
          className="group p-4 rounded-2xl text-black bg-white border shadow-md hover:shadow-none hover:bg-arsen-tertiary hover:text-white transition duration-500 w-full"
        >
          <div className="text-4xl mb-2 font-bold">ASO</div>
          <div className="text-sm font-medium mb-2 text-arsen-tertiary group-hover:text-white transition duration-500">
            <i>
              <b>A</b>irlangga <b>S</b>tatistics <b>O</b>lympiad
            </i>
          </div>
          <div className="mb-2 px-2 py-1 text-sm text-arsen-tertiary bg-arsen-tertiary-100 group-hover:bg-arsen-tertiary-400 group-hover:text-white rounded-lg transition duration-500">
            Utilizing Statistical Critical Thinking & Problem Solving Skills in
            Data Driven Society
          </div>
          <div className="text-sm  text-left text-arsen-primary group-hover:text-white transition duration-500">
            {/* <ul className="space-y-4">
              <li className="flex items-center">
                <CheckRoundedSVG />
                <p className="ml-2">Terbuka untuk Umum</p>
              </li>
              <li className="flex items-center">
                <CheckRoundedSVG />
                <p className="ml-2">Pemateri Berpengalaman</p>
              </li>
              <li className="flex items-center">
                <CheckRoundedSVG />
                <p className="ml-2">E-Sertifikat</p>
              </li>
            </ul> */}
          </div>
        </Link>
      </div>
    </div>
  );
}

export default EventsPage;

import React from "react";
import { Link } from "react-router-dom";

function ContactCard({ event }) {
  return (
    <div className="flex flex-col gap-3 my-16">
      <div className="font-bold text-sm tracking-widest text-gray-600 uppercase">
        Contact Us
      </div>
      <div className="flex flex-col gap-2.5">
        <div className="text-sm text-gray-700">
          Jika Anda memiliki pertanyaan atau pertanyaan, jangan ragu untuk
          menghubungi kami melalui saluran berikut:
        </div>
        <div className="flex flex-col gap-3 text-left">
          <div className="flex flex-col text-sm text-gray-700 gap-1.5 text-center md:text-left">
            <span className=" font-bold ">Email</span>
            <span>arsenunair@gmail.com</span>
          </div>
          {event === "Opening" && (
            <div className="flex flex-col text-sm text-gray-700 gap-1.5 text-center md:text-left">
              <span className=" font-bold ">WhatsApp</span>
              <span>
                Registrasi:{" "}
                <Link
                  className="text-blue-700 hover:text-black transition duration-300"
                  to="https://wa.me/6289516064249"
                >
                  +62 895-1606-4249 (Maria)
                </Link>
              </span>
              <span>
                Teknis Acara:{" "}
                <Link
                  className="text-blue-700 hover:text-black transition duration-300"
                  to="https://wa.me/6281246837396"
                >
                  +62 812-4683-7396 (Nathania)
                </Link>
              </span>
            </div>
          )}
          {event === "ASIC" && (
            <div className="flex flex-col text-sm text-gray-700 gap-1.5 text-center md:text-left">
              <span className=" font-bold ">WhatsApp</span>
              <span>
                Registration:{" "}
                <Link
                  className="text-arsen-primary hover:text-black transition duration-300"
                  to="https://wa.me/6285755399336"
                >
                  +62 857-5539-9336 (Nayla)
                </Link>
              </span>
              <span>
                Technical:{" "}
                <Link
                  className="text-arsen-primary hover:text-black transition duration-300"
                  to="https://wa.me/6285850353835"
                >
                  +62 858-5035-3835 (Sanda)
                </Link>
              </span>
            </div>
          )}
          {event === "ASEC" && (
            <div className="flex flex-col text-sm text-gray-700 gap-1.5 text-center md:text-left">
              <span className=" font-bold ">WhatsApp</span>
              <span>
                Registration:{" "}
                <Link
                  className="text-arsen-secondary hover:text-black transition duration-300"
                  to="https://wa.me/6281217040203"
                >
                  +62 812-1704-0203 (Umi)
                </Link>
              </span>
              <span>
                Technical:{" "}
                <Link
                  className="text-arsen-secondary hover:text-black transition duration-300"
                  to="https://wa.me/6281246837396"
                >
                  +62 812-4683-7396 (Nathania)
                </Link>
              </span>
            </div>
          )}
          {event === "ASO" && (
            <div className="flex flex-col text-sm text-gray-700 gap-1.5 text-center md:text-left">
              <span className=" font-bold ">WhatsApp</span>
              <span>
                Registration:{" "}
                <Link
                  className="text-blue-700 hover:text-black transition duration-300"
                  to="https://wa.me/6289516064249"
                >
                  +62 895-1606-4249 (Maria)
                </Link>
              </span>
              <span>
                Technical:{" "}
                <Link
                  className="text-blue-700 hover:text-black transition duration-300"
                  to="https://wa.me/6285156570424"
                >
                  +62 851-5657-0424 (Nararya)
                </Link>
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ContactCard;

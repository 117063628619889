import React from "react";

export function TimelineBlock({ date, children }) {
  return (
    <div className="flex self-stretch px-4 py-2.5 rounded-2xl shadow border border-gray-600 flex-col justify-center items-center">
      <div className="self-stretch text-center md:text-left text-black text-xs font-semibold leading-[21px]">
        {date}
      </div>
      <div className="self-stretch text-center md:text-left text-stone-500 text-sm font-normal leading-normal">
        {children}
      </div>
    </div>
  );
}

export function Timeline({ month, children }) {
  return (
    <div data-aos="zoom-in" className="flex flex-col gap-2.5">
      <div className="font-bold text-lg tracking-widest text-gray-800 uppercase">
        {month}
      </div>
      <div className="flex flex-col md:flex-row gap-2.5 flex-wrap">
        {children}
      </div>
    </div>
  );
}

import React from "react";
import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NavigationBar from "../component/NavigationBar";
import Footer from "../component/Footer";

function Layout() {
  return (
    <div className="flex flex-col">
      <ToastContainer position="bottom-right" />
      <NavigationBar className="sticky top-0 z-50" />
      <div className="flex flex-col flex-grow w-full mt-16">
        <Outlet />
      </div>
      <Footer />
    </div>
  );
}

export default Layout;
